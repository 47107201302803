import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {Text, Icon} from '@lana-ui/common';

import './Navbar.scss';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Amp.
	 */
	amp: PropTypes.bool,
};

const Navbar = ({className, amp, toggleDropdown, ...r}) => {
	const classes = classNames('navbar', className);

	return (
		<nav className={classes} {...r}>
			<ul className="row">
				<li className="col-3 --item">
					<Icon
						name="home"
						href="https://www.lanacion.com.ar/"
						target="_top"
						ariaLabel="Redirigir a la home de LA NACION."
					/>
					<Text size="6xs" text="Home" />
				</li>
				<li className="col-3 --item" onClick={toggleDropdown}>
					<Icon
						name="sections"
						href="#"
						ariaLabel="Abrir el menú lateral de las secciones."
					/>
					<Text size="6xs" text="Secciones" />
				</li>
				<li className="col-3 --item">
					<Icon
						name="club"
						href="https://club.lanacion.com.ar/"
						ariaLabel="Redirigir a CLUB LA NACION."
					/>
					<Text size="6xs" text="Club LA NACION" />
				</li>
				<li className="col-3 --item">
					<Icon
						name="user"
						href="https://myaccount.lanacion.com.ar/mi-usuario/"
						ariaLabel="Redirigir a MI CUENTA, LA NACION."
					/>
					<Text size="6xs" text="Mi Cuenta" />
				</li>
			</ul>
		</nav>
	);
};

Navbar.propTypes = propTypes;

export default Navbar;
