import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
	getFontFamily,
	getFontSize,
	getFontWeight,
} from '../helpers/getFontData';

import './Link.scss';

const propTypes = {
	/**
	 * href link.
	 */
	href: PropTypes.string,
	/**
	 * Texto dentro del link.
	 */
	text: PropTypes.string,
	/**
	 * Target.
	 */
	target: PropTypes.string,
	/**
	 * Atributo rel asociado.
	 */
	rel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	/**
	 * Title del elemento
	 */
	title: PropTypes.string,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Tamaño de la fuente.
	 */
	size: PropTypes.string,
};

const Link = ({
	href,
	children,
	text,
	rel,
	target,
	title,
	onClick,
	className,
	font,
	size,
	weight,
	bold,
	...r
}) => {
	const _font = getFontFamily(font);
	const _size = getFontSize(size);
	const _weight = getFontWeight(weight);

	const classes = classNames('com-link', className, {
		[_font]: font,
		[_size]: size,
		[_weight]: weight,
		'--font-bold': bold,
	});
	const content = children || text;

	return (
		<a
			className={classes}
			href={href}
			target={target}
			{...(rel && {
				rel: typeof rel === 'string' ? rel : 'noopener nofollow',
			})}
			title={title || text}
			onClick={onClick}
			{...r}
		>
			{content}
		</a>
	);
};

Link.propTypes = propTypes;

export default Link;
