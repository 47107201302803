/**
 * Resuelve los espaciados del componente grilla.
 *
 * @param {Object} spacings - Espaciados de las distintas resoluciones.
 * @returns {Object} - Objeto que resuelve las clases.
 */
export const resolveSpacings = (spacings = []) => {
	const aux = {};

	Object.entries(spacings).forEach(([name, spacing]) => {
		const size = name.replace('spacing', '').toLowerCase();

		if ([typeof spacing].some(t => ['string', 'number'].includes(t)) || !spacing) {
			Object.assign(aux, { [`--s-${size ? `${size}-` : ''}${spacing}`]: spacing });
		} else {
			Object.entries(spacing).forEach(([axis, spacing]) => {
				Object.assign(aux, {
					[`--s-${axis}-${size ? `${size}-` : ''}${spacing}`]: axis,
				});
			});
		}
	});

	return aux;
};

/**
 * Resuelve las columnas del componente grilla y los items.
 *
 * @param {Object | Number} spacings - Columnas en las distintas resoluciones o una general.
 * @returns {Object} - Objeto que resuelve las clases.
 */
export const resolveCols = (cols = []) => {
	const aux = {};

	if ([typeof cols].some(t => ['string', 'number'].includes(t)) || !cols)
		Object.assign(aux, { [`--col-${cols}`]: cols });
	else {
		Object.entries(cols).forEach(([resolution, cols]) => {
			Object.assign(aux, { [`--col-${resolution}-${cols}`]: cols });
		});
	}

	return aux;
};
