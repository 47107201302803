import PropTypes from 'prop-types';

import './_checkmark.scss';
import classNames from 'classnames';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Color del checkmark.
	 */
	checkmarkColor: PropTypes.string,
	/**
	 * Flag, ¿se encuentra activo?
	 */
	state: PropTypes.bool,
	/**
	 * Flag, ¿posee un contenedor?
	 */
	isWrapped: PropTypes.bool,
};

const Checkmark = ({ className, checkmarkColor, state, isWrapped, ...r }) => {
	const classes = classNames('checkmark', className, {
		'--active': state,
		'--regular': state === undefined,
		'--wrapped': isWrapped,
	});
	const content = (
		<svg
			className={classes}
			xmlns="http://www.w3.org/2000/svg"
			version="1"
			width="16"
			height="16"
			x="9"
			y="9"
			viewBox={!isWrapped ? '0 0 24 24' : '0 0 26 26'}
			{...r}
		>
			<path
				fill="none"
				stroke={checkmarkColor ? `#${checkmarkColor}` : '#000'}
				strokeMiterlimit="10"
				strokeWidth="2"
				d="M20 6L9 17l-5-5"
			></path>
		</svg>
	);

	return !isWrapped ? (
		<>{content}</>
	) : (
		<svg
			className="checkmark-wrapper"
			xmlns="http://www.w3.org/2000/svg"
			version="1"
			width="32"
			height="32"
		>
			<circle r="15" cx="16" cy="16" fill="none" />
			{content}
		</svg>
	);
};

Checkmark.propTypes = propTypes;

export default Checkmark;
