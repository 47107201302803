import {useReducer, useEffect, useContext} from 'react';
import cookies from 'react-cookies';
import {reducer} from '../lib.utils';
import config from '../lib.config';
import {NewsletterContext} from '@lib/contexts';

const useUser = () => {
	const initialState = {
		isLogged: false,
		isPremium: false,
		username: '',
		email: '',
	};
	const [state, dispatch] = useReducer(reducer, initialState);
	const {token, username, email, isPremium, isMobile, cookieOptions} =
		useContext(NewsletterContext);
	useEffect(() => {
		if (!token) {
			dispatch({type: 'isLogged', value: false});
		} else {
			dispatch({type: 'isLogged', value: true});
			dispatch({
				tpye: 'username',
				value: username,
			});
			dispatch({type: 'email', value: email});
			dispatch({
				type: 'isPremium',
				value: isPremium,
			});
		}
	}, [token, username, email, isPremium]);

	const suscribe = () => {

		window.location.href = `${config.paywallUrl}${window.btoa(window.location.href)}`;
	};

	const login = () => {
		window.location.href =
			config.loginUrl + window.btoa(window.location.href);
	};

	const logout = callback => {
		if (callback) {
			callback();
		}

		const properties = isMobile
			? {embedShortCircuit: true}
			: {refresh: true};
		window.UserClientLibs &&
			window.UserClientLibs.LogoutAsync &&
			window.UserClientLibs.LogoutAsync(properties);
		dispatch({type: 'isLogged', value: false});

		cookies.remove('token', cookieOptions);
		cookies.remove('ProductoPremiumId', cookieOptions);
	};

	return {
		...state,
		suscribe,
		login,
		logout,
	};
};

export default useUser;
