import PropTypes from 'prop-types';

import classNames from 'classnames';

import Icon from '../icon';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.array,
	/**
	 * Slider actual.
	 */
	current: PropTypes.number,
	/**
	 * Setter del slider actual.
	 */
	setCurrent: PropTypes.func,
	/**
	 * Handler para ir una posición
	 * hacia adelante.
	 */
	goNext: PropTypes.func,
	/**
	 * Handler para ir una posición
	 * hacia atrás.
	 */
	goBack: PropTypes.func,
	/**
	 * Flag, ¿tipo de controles alternativo?
	 */
	alernativeControls: PropTypes.bool,
};

const ArrowControls = ({ className, current, setCurrent, goBack, goNext, alternative, ...r }) => {
	const classes = classNames('carousel__arrow-controls', className, {
		'--alternative': alternative,
	});

	return (
		<nav className={classes} {...r}>
			<button
				className="carousel__back"
				aria-label="Ir una slide hacia la izquierda."
				onClick={() => goBack(current, setCurrent)}
			>
				<Icon name="arrow-left" size="l" />
			</button>
			<button
				className="carousel__next"
				aria-label="Ir una slide hacia la derecha."
				onClick={() => goNext(current, setCurrent)}
			>
				<Icon name="arrow-right" size="l" />
			</button>
		</nav>
	);
};

ArrowControls.propTypes = propTypes;

export default ArrowControls;
