import {forwardRef, useEffect} from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import './_newsletter-article.scss';

import {
	Title,
	Button,
	Picture,
	Paragraph,
	Chip,
	Icon,
} from '@lana-ui/common';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Link asociado a la imagen.
	 */
	imgSrc: PropTypes.string,
	/**
	 * Títutlo asociado.
	 */
	title: PropTypes.string,
	/**
	 * Link asociado al ejemplo.
	 */
	exampleSrc: PropTypes.string,
	/**
	 * Flag, ¿es seleccionable?
	 */
	isSelectable: PropTypes.bool,
	/**
	 * Flag, ¿está seleccionado?
	 */
	isSelected: PropTypes.bool,
	/**
	 * Flag, ¿es exclusivo?
	 */
	isExclusive: PropTypes.bool,
	/**
	 * Flag, ¿se encuentra logeado
	 * el usuario?
	 */
	isLogged: PropTypes.bool,
	/**
	 * Flag, ¿se está procesando
	 * información?
	 */
	isLoading: PropTypes.bool,
	/**
	 * Flag, ¿tiene suscripción?
	 */
	isPremium: PropTypes.bool,
	/**
	 * Label asociado a la imagen.
	 */
	label: PropTypes.string,
	/**
	 * Handler del botón
	 * para el evento click.
	 */
	handlerClick: PropTypes.func,
	/**
	 * Texto alternativo de la imagen.
	 */
	alt: PropTypes.string,
	/**
	 * Flag, ¿carga perezosa?
	 */
	lazy: PropTypes.bool,
	/**
	 * Clases adicionales del chip.
	 */
	chipClassName: PropTypes.string,
};

const defaultProps = {
	handlerClick: () => {},
	lazy: true,
};

const NewsletterArticle = forwardRef(
	(
		{
			children,
			className,
			chipClassName,
			imgSrc,
			title,
			exampleSrc,
			isSelectable,
			isSelected,
			isExclusive,
			isLogged,
			isLoading,
			label,
			handlerClick,
			isPremium,
			alt,
			lazy,
			fechaAlta,
			suscripto,
			onMounted,
			...r
		},
		ref,
	) => {
		const classes = classNames('newsletter-article', className, {
			'--exclusive': isExclusive,
		});
		const chipClasses = classNames(chipClassName, {
			'--negative': isExclusive,
		});

		useEffect(() => {
			if (window.location.hash===`#${r.id}`) onMounted(true)
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])
		
		return (
			<article className={classes} ref={ref} {...r}>
				<Picture src={imgSrc} label={label} alt={alt} lazy={lazy} />
				<Title size="m" as="h3" className="--font-bold --sueca">
					{title}
				</Title>
				<Paragraph size="twoxs" className="description">
					{children}
				</Paragraph>
				{(isSelectable || (isExclusive && isLogged && isPremium)) && (
					<Chip
						{...(!isLogged && {
							icon: <Icon name="plus" size="s" />,
							isSelectable: true,
						})}
						{...(isLogged && {type: 'secondary'})}
						type={isSelected ? 'primary' : 'secondary'}
						{...!isSelected && ({icon: <Icon name="plus" size="s" />})}
						className={chipClasses}
						pointer
						iconLeft
						isSelectable
						handlerClick={handlerClick}
						isSelected={isSelected}
						isLoading={isLoading}
						isLogged={isLogged}
					>
						{isLogged && isSelected
							? 'RECIBIENDO'
							: isLogged && !isSelected
							? 'RECIBIR NEWSLETTER'
							: isSelected
							? 'SELECCIONADO'
							: 'SELECCIONAR'}
					</Chip>
				)}
				<Button
					tag="a"
					target="_blank"
					mod="tertiary"
					href={exampleSrc}
					label="EJEMPLO"
				/>
			</article>
		);
	},
);

NewsletterArticle.propTypes = propTypes;
NewsletterArticle.defaultProps = defaultProps;

export default NewsletterArticle;
