import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {getFontSize} from '../helpers/getFontData';

import Link from '../link';
import {Button} from '@lana-ui/common';
import './List.scss';

const propTypes = {
	order: PropTypes.bool,
	inline: PropTypes.bool,
	className: PropTypes.string,
	size: PropTypes.string,
};

const List = ({children, order, inline, className, size}) => {
	const _size = getFontSize(size);

	const CustomTag = order ? 'ol' : 'ul';
	const classes = classNames('com-list', className, {
		[_size]: size,
		'--ordered': order,
		'--inline': inline,
	});

	return (
		<CustomTag className={classes}>
			{children.map((item, index) => (
				<li key={index}>
					{item.href ? (
						<Link
							text={item.text}
							href={item.href}
							target={item.target}
							rel={item.rel}
							title={item.title || `Ir a ${item.text}`}
						/>
					) : item.onClick ? (
						<Button
							link
							label={item.text}
							onClick={item.onClick}
							title={item.title}
						/>
					) : (
						item.text
					)}
				</li>
			))}
		</CustomTag>
	);
};

List.propTypes = propTypes;

export default List;
