import {EXCLUSIVE, NEWSLETTER} from '../pages/Landing/data';

export const TYPE_EXCLUSIVE = {
	SPECIALS: 'SPECIALS',
};

export const TYPE_NEWSLETTER = {
	REGULAR: 'REGULAR',
	CLUB: 'CLUB',
	MAGAZINES: 'MAGAZINES',
};

export const LandingMapper = {
	parseToExclusive: (
		subscriptions = [],
		TypeExclusive = TYPE_EXCLUSIVE.SPECIALS,
	) => {
		if (subscriptions.length === 0) {
			return subscriptions;
		}

		const subextract = subscriptions.filter(
			subs =>
				EXCLUSIVE[TypeExclusive].find(
					s => s.id === subs.servicio.id,
				) !== undefined,
		);
		return EXCLUSIVE[TypeExclusive].map(s => {
			const sibling = subextract.find(subs => subs.servicio.id === s.id);
			if (sibling === undefined) {
				return false;
			}

			const result = {
				...s,
				...sibling,
				title: sibling.servicio.nombre,
				children: sibling.servicio.descripcion,
				label: sibling.servicio.frecuencia.toUpperCase(),
				isSelected: sibling.suscripto,
				imgSrc: sibling.servicio.image,
			};
			delete result['servicio'];

			return result;
		}).filter(s => s);
	},

	parseToNewsletter: (
		subscriptions = [],
		TypeNewsletter = TYPE_NEWSLETTER.REGULAR,
	) => {
		if (subscriptions.length === 0) {
			return subscriptions;
		}

		const subextract = subscriptions.filter(
			subs =>
				NEWSLETTER[TypeNewsletter].find(
					s => s.id === subs.servicio.id,
				) !== undefined,
		);
		const subservicios = [];
		let servicios = NEWSLETTER[TypeNewsletter].map(s => {
			const sibling = subextract.find(subs => subs.servicio.id === s.id);
			if (sibling === undefined) {
				return false;
			}

			const result = {
				...s,
				...sibling,
				alt: `Newsletter de ${sibling?.servicio.nombre || s?.title}`,
				title: sibling?.servicio.nombre || s?.title,
				children: (sibling?.servicio?.descripcion ||
							  sibling?.servicio?.nombre ||
							  sibling?.nombre),
				label: sibling?.servicio.frecuencia?.toUpperCase(),
				isSelected: sibling?.suscripto || false,
				imgSrc: sibling?.servicio.image,
			};

			// Si contiene subservicios, se determina si está seleccionado o no por el total de ellos

			if (sibling?.subservicios?.length) {
				result.isSelected = !sibling?.subservicios.some(
					s => !s.suscripto,
				);
			}

			if (
				TYPE_NEWSLETTER.CLUB === TypeNewsletter &&
				sibling?.subservicios
			) {
				subservicios.push(
					...LandingMapper.parseToNewsletter(
						sibling.subservicios,
						TypeNewsletter,
					),
				);
			}

			delete result['servicio'];

			return result;
		}).filter(s => s);

		if (subservicios.length) {
			servicios = servicios.concat(
				...subservicios.filter(
					sub => !servicios.some(s => sub.id === s.id),
				),
			);
		}

		return servicios;
	},

	/**
	 * @param {Object[]} subscriptions Array con los servicios en formato json de suscripciones existentes
	 * @param {Boolean?} subscripto
	 * True -> indica que se quieren los ids de suscripciones del usuario
	 * False -> indicia que se quieren los ids de suscripciones a los que el usuario no está suscripto
	 * Null -> indica que se quieren los ids tantos de los que el usuario está suscripto como no
	 * @param {String} keyObject Identificador del objeto a recorrer
	 * @param {String} keyValue Identificador del id del servicio dentro del objeto recorrido
	 * @returns Array de ids de servicios
	 */
	extractSubscriptionsInLevel1: (
		subscriptions = [],
		subscripto = null,
		keyObject = 'servicio',
		keyValue = 'id',
	) => {
		if (subscriptions.length === 0 || keyObject === '') {
			return subscriptions;
		}

		const subscriptionsInLevel1 = [];
		for (const subs of subscriptions) {
			const object = subs[keyObject];
			if (object) {
				if (subscripto == null) {
					subscriptionsInLevel1.push(object[keyValue]);
				} else {
					if (subscripto && subs.suscripto) {
						subscriptionsInLevel1.push(object[keyValue]);
					} else if (!subscripto && !subs.suscripto) {
						subscriptionsInLevel1.push(object[keyValue]);
					}
				}

				if (subs.subservicios != null) {
					const result = LandingMapper.extractSubscriptionsInLevel1(
						subs.subservicios,
						subscripto,
					);
					if (result.length > 0) {
						subscriptionsInLevel1.push(...result);
					}
				}
			}
		}

		return subscriptionsInLevel1;
	},
};
