const SPECIALS = [
	{
		id: 45,
		title: 'Joaquín Morales Solá',
		exampleSrc: './samples/morales.html',
		label: 'MARTES',
		children: 'Recibí en tu buzón de email sus columnas de opinión antes de que salgan publicadas.',
	},
	{
		id: 124,
		title: 'Carlos Pagni',
		exampleSrc: './samples/pagni.html',
		label: 'MIÉRCOLES',
		children: 'Recibí en tu buzón de email sus columnas de opinión antes de que salgan publicadas.',
	},
	{
		id: 144,
		title: 'Jorge Fernández Díaz',
		exampleSrc: './samples/fernandez.html',
		label: 'SÁBADOS',
		children: 'Recibí en tu buzón de email sus columnas de opinión antes de que salgan publicadas.',
	},
	{
		id: 217,
		title: 'Inés Capdevila',
		exampleSrc: './samples/capdevila.html',
		label: 'SÁBADOS, CADA QUINCE DÍAS',
		children: 'Recibí en tu buzón de email sus columnas de opinión antes de que salgan publicadas.',
	},
	{
		id: 232,
		title: 'José Del Rio',
		exampleSrc: './samples/delrio.html',
		label: 'SÁBADOS',
		children: 'Recibí en tu buzón de email sus columnas de opinión antes de que salgan publicadas.',
	},
	{
		id: 179,
		title: 'Martín Rodriguez Yebra',
		exampleSrc: './samples/yebra.html',
		label: 'SÁBADOS',
		children: 'Recibí en tu buzón de email sus columnas de opinión antes de que salgan publicadas.',
	},
	{
		id: 220,
		title: 'Laura Di Marco',
		exampleSrc: './samples/dimarco.html',
		label: 'MIÉRCOLES, CADA QUINCE DÍAS',
		children: 'Recibí en tu buzón de email sus columnas de opinión antes de que salgan publicadas.',
	},
	{
		id: 233,
		title: 'Jorge Liotti',
		exampleSrc: './samples/liotti.html',
		label: 'SÁBADOS',
		children: 'Recibí en tu buzón de email sus columnas de opinión antes de que salgan publicadas.',
	},
	{
		id: 149,
		title: 'Claudio M. Reymundo Roberts',
		exampleSrc: './samples/reymundoroberts.html',
		label: 'VIERNES',
		children: 'Recibí en tu buzón de email sus columnas de opinión antes de que salgan publicadas.',
	},
	{
		id: 163,
		title: 'Claudio Jacquelin',
		exampleSrc: './samples/jacquelin.html',
		label: 'JUEVES Y DOMINGOS',
		children: 'Recibí en tu buzón de email sus columnas de opinión antes de que salgan publicadas.',
	},
	{
		id: 235,
		title: 'Florencia Donovan',
		exampleSrc: './samples/donovan.html',
		label: 'JUEVES',
		children: 'Recibí en tu buzón de email sus columnas de opinión antes de que salgan publicadas.',
	},
	{
		id: 236,
		title: 'Juan Carlos de Pablo',
		exampleSrc: './samples/depablo.html',
		label: 'MIÉRCOLES',
		children: 'Recibí en tu buzón de email sus columnas de opinión antes de que salgan publicadas.',
	},
	{
		id: 219,
		title: 'Claudio Zuchovicki',
		exampleSrc: './samples/zucho.html',
		label: 'SÁBADOS, CADA QUINCE DÍAS',
		children: 'Recibí en tu buzón de email sus columnas de opinión antes de que salgan publicadas.',
	},
	{
		id: 234,
		title: 'Luciano Román',
		exampleSrc: './samples/roman.html',
		label: 'MIÉRCOLES',
		children: 'Recibí en tu buzón de email sus columnas de opinión antes de que salgan publicadas.',
	},
	{
		id: 221,
		title: 'A Fondo',
		children:
			'Descubrí el resumen con nuestras producciones más destacadas en un newsletter exclusivo.',
		exampleSrc: './samples/afondo.html',
		label: 'UNA VEZ POR SEMANA',
	},
	{
		id: 12,
		title: 'LN Revistas',
		children:
			'Recibí antes que nadie anticipos y contenido diferencial de todas las revistas de LN.',
		exampleSrc: './samples/revistas/revistas.html',
		label: 'TRES VECES POR MES',
	},
	{
		id: 218,
		title: 'LN Especiales',
		children:
			'Recibí un resumen con las notas más leídas, las mejores infografías y la invitación a los mejores eventos.',
		exampleSrc: './samples/especiales/especiales.html',
		label: 'DOS VECES POR SEMANA',
	},
	{
		id: 226,
		title: '¿Qué veo?',
		children: 'Una selección de series, películas y producciones recomendadas por los editores y especialistas de LA NACION.',
		exampleSrc: './samples/que-veo.html',
		label: 'VIERNES',
	},
	{
		id: 239,
		title: 'Series exclusivas',
		children: 'Recibí en tu buzón una selección de los contenidos exclusivos para suscriptores de LA NACION.',
		exampleSrc: './samples/series-exclusivas.html',
		label: 'VIERNES',
	},
	{
		id: 17,
		title: 'Cultura',
		children: 'Recibí las noticias del mundo del arte y literatura, más recomendaciones de lecturas y actividades culturales.',
		exampleSrc: './samples/cultura.html',
		label: 'JUEVES',
	},
	{
		id: 229,
		title: 'Guía Tech',
		children: 'Las historias y noticias más destacadas del mundo tecnológico, Internet y la cultura digital.',
		exampleSrc: './samples/guia-tech.html',
		label: 'MARTES',
	},
	{
		id: 230,
		title: 'Trivias',
		children: 'Poné a prueba tu conocimiento con las trivias exclusivas para los suscriptores de LA NACION.',
		exampleSrc: './samples/trivias.html',
		label: 'LUNES A VIERNES',
	},
	{
		id: 90,
		title: 'Comunidad',
		children: 'Las noticias destacadas y todo lo que necesitás saber sobre los temas sociales más urgentes.',
		exampleSrc: './samples/comunidad.html',
		label: 'UNA VEZ POR SEMANA',
	},
].map(({...d}, i) => ({
	...d,
	isExclusive: true,
	alt: `Newsletter de ${d.title}`,
	lazy: false,
}));

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
	SPECIALS,
};
