import PropTypes from 'prop-types';

const propTypes = {
	/**
	 * Breakpoint.
	 */
	media: PropTypes.number,
	/**
	 * URL de la imagen.
	 */
	srcSet: PropTypes.string,
};

const Source = ({ media, srcSet, ...r }) => {
	return srcSet && <source media={`(min-width: ${media}px)`} srcSet={srcSet} {...r} />;
};

Source.propTypes = propTypes;

export default Source;
