import { useEffect, useState } from 'react';

const useElementDimensions = (ref, windowSize) => {
	const [dimensions, setDimensions] = useState({});

	useEffect(() => {
		if (ref.current) {
			const { current } = ref;
			const boundingRect = current.getBoundingClientRect();
			const { width, height } = boundingRect;
			setDimensions({ width: width, height: height });
		}
	}, [ref, windowSize]);

	return dimensions;
};

export default useElementDimensions;
