import PropTypes from 'prop-types';

import classNames from 'classnames';
import './_layout.scss';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Tipo de componente a utilizar.
	 */
	as: PropTypes.string,
	/**
	 * Flag, ¿dark mode?
	 */
	darkMode: PropTypes.bool,
	/**
	 * Tipo de layout.
	 */
	state: PropTypes.oneOf(['wrapper']),
};

const defaultProps = {
	as: 'main',
};

const Layout = ({
	children,
	className,
	wrapperClassName,
	as: Component,
	state,
	darkMode,
	...r
}) => {
	const classes = classNames('lay', className, { [`--${state}`]: state, 'mode:dark': darkMode });

	return (
		<Component className={classes} {...r}>
			{children}
		</Component>
	);
};

Layout.propTypes = propTypes;
Layout.defaultProps = defaultProps;

export default Layout;
