import {useReducer, useEffect, useContext, useState} from 'react';
import {reducer} from '../lib.utils';
import config from '../lib.config';
import {EXCLUSIVE, NEWSLETTER} from '../../pages/Landing/data';
import useFetch from '../../components/common/helpers/useFetch';
import {NewsletterContext} from '@lib/contexts';
import {
	LandingMapper,
	TYPE_EXCLUSIVE,
	TYPE_NEWSLETTER,
} from '../lib.mapper';

const useSelections = isLogged => {
	const initialState = {
		selectedNewsletters: [],
		unselectedNewsletters: [],
		isValidInput: false,
		isLoading: false,
		updateStatus: {status: '', message: ''},
		showToast: false,
		select: false,
		bajadas: {
			exclusive: EXCLUSIVE,
			newsletter: NEWSLETTER,
		},
	};
	const {token, accessToken} = useContext(NewsletterContext);
	const [isDropdownActive, setIsDropdownActive] = useState(false);
	const [state, dispatch] = useReducer(reducer, initialState);
	const urlApi = `${config.newsletterApi}/${
		token ? 'Suscripciones' : 'Servicios'
	}`;
	const urlApiSubscription = `${config.newsletterApi}/Suscripciones`;
	const [{data, loading}, executeFetch] = useFetch(urlApi, {
		method: 'GET',
		headers: {
			'X-Token': token,
			'Authorization': `Bearer ${accessToken}`,
		},
	});

	/**
	 * Muestra/Oculta el toast.
	 */
	const setShowToast = show => {
		dispatch({type: 'showToast', value: show});
	};

	/**
	 * Oculta el dropdown.
	 */
	const closeDropdown = () => {
		setIsDropdownActive(false);
		document.body.classList.remove('drawer-on');
	};

	/**
	 * Muestra/Oculta el dropdown.
	 */
	const toggleDropdown = () => {
		setIsDropdownActive(!isDropdownActive);
		isDropdownActive
			? document.body.classList.remove('drawer-on')
			: document.body.classList.add('drawer-on');
	};

	/**
	 * Agrega o remueve un newsletter,
	 * de la lista.
	 *
	 * @param {Number} newsletterId - ID del newsletter.
	 */
	const selectNewsletter = newsData => {
		const selected = state.selectedNewsletters.some(n => n === newsData.id);
		syncSubscription(newsData.id, selected);

		dispatch({
			type: 'updateStatus',
			value: {status: '', message: ''},
		});

		if (!state.select) {
			dispatch({
				type: 'select',
				value: true,
			});
		}
	};

	/**
	 * Sincroniza el estado de las suscripciones y desuscripciones
	 * @param {Number} id Identificador del servicio
	 * @param {Boolean} selected Estado al cual poner la suscripción
	 */
	const syncSubscription = (id, selected) => {
		if (selected) {
			// Si es una opción de club
			if (
				state.bajadas.newsletter.CLUB.some(
					s => s.id === id && !syncSubscriptionAnyClub(selected, id),
				)
			) {
				return;
			}
			dispatch({
				type: 'selectedNewsletters',
				value: state.selectedNewsletters.filter(n => n !== id),
			});

			dispatch({
				type: 'unselectedNewsletters',
				value: [...state.unselectedNewsletters, id],
			});
		}
		// si está deseleccionado, lo puedo seleccionar
		else {
			// Si es una opción de club
			if (
				state.bajadas.newsletter.CLUB.some(
					s => s.id === id && !syncSubscriptionAnyClub(selected, id),
				)
			) {
				return;
			}

			dispatch({
				type: 'unselectedNewsletters',
				value: state.unselectedNewsletters.filter(n => n !== id),
			});

			dispatch({
				type: 'selectedNewsletters',
				value: [...state.selectedNewsletters, id],
			});
		}
	};

	/**
	 * Sincroniza el array de suscripciones de club, para que cuando se seleccione de a una cada opción completando todas,
	 * el botón de seleccionar todos cambie también. Lo mismo a la inversa, si se deselecciona cada una de manera individual;
	 * el botón para todos cambiará también
	 * @param {Boolean} selected Flag para indicar el estado actual del elemento a sincronizar
	 * @param {Number} newsletterIdToAdd Identificador del servicio clickeado
	 * @returns Retorna true / false para indicar si se debe continuar con la sincronización o no
	 */
	const syncSubscriptionAnyClub = (selected, newsletterIdToAdd) => {
		// Quito del array de selección y deselección el servicio root de club junto con el clickeado para trabajar en limpio
		const arrayIds = [newsletterIdToAdd];
		const expresionExclude = n => !arrayIds.some(x => x === n);
		const selectedArray =
			state.selectedNewsletters.filter(expresionExclude);
		const unSelectedArray =
			state.unselectedNewsletters.filter(expresionExclude);

		// Lo que está deseleccinado, se selecciona
		if (!selected) {
			dispatch({
				type: 'selectedNewsletters',
				value: [...selectedArray, ...arrayIds],
			});
			dispatch({
				type: 'unselectedNewsletters',
				value: [...unSelectedArray],
			});

			return false;
		}

		// y lo que está seleccionado, se deselecciona
		if (selected) {
			// Si me quedo entre lo seleccionado
			const anyClub = selectedArray.some(n =>
				state.bajadas.newsletter.CLUB.some(s => s.id === n),
			);
			if (anyClub) {
				unSelectedArray.push(newsletterIdToAdd);
			} else {
				unSelectedArray.push(...arrayIds);
			}

			dispatch({
				type: 'selectedNewsletters',
				value: [...selectedArray],
			});

			dispatch({
				type: 'unselectedNewsletters',
				value: [...unSelectedArray],
			});

			return false;
		}

		return true;
	};

	/**
	 * Syncroniza el estado de selección de las suscripciones del usuario con los datos existentes
	 */
	const syncSubscriptions = () => {
		if (isLogged) {
			const specialsSubscriptions =
				state.bajadas.exclusive.SPECIALS.filter(
					({isSelected}) => isSelected,
				).map(({id}) => id) || [];
			const advencesSubscriptions =
				state.bajadas.exclusive.ADVANCES.filter(
					({isSelected}) => isSelected,
				).map(({id}) => id) || [];
			const regularSubscriptions =
				state.bajadas.newsletter.REGULAR.filter(
					({isSelected}) => isSelected,
				).map(({id}) => id) || [];
			const clubSubscriptions =
				state.bajadas.newsletter.CLUB.filter(
					({isSelected}) => isSelected,
				).map(({id}) => id) || [];
			const magazinesSubscriptions =
				state.bajadas.newsletter.MAGAZINES.filter(
					({isSelected}) => isSelected,
				).map(({id}) => id) || [];
			dispatch({
				type: 'selectedNewsletters',
				value: [
					...specialsSubscriptions,
					...advencesSubscriptions,
					...regularSubscriptions,
					...clubSubscriptions,
					...magazinesSubscriptions,
				],
			});
		}
	};

	/**
	 * Actualiza las suscripciones.
	 *
	 * @param {Event} e
	 * @param {Object} object - Contiene la información del NL
	 */
	const updateSubscriptions = async (e, {id} = {}) => {
		e.preventDefault();
		dispatch({type: 'isLoading', value: true});

		if (!isLogged) {
			dispatch({type: 'isValidInput', value: false});
			try {
				const email = e.target.querySelector('input').value;
				await executeFetch(urlApiSubscription, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						email,
						serviciosAgregar: state.selectedNewsletters,
						serviciosRemover: [],
					}),
				});

				dispatch({
					type: 'updateStatus',
					value: {status: 'success', message: ''},
				});
			} catch {
				dispatch({
					type: 'updateStatus',
					value: {
						status: 'error',
						message:
							'No pudimos suscribirte al newsletter. Volvé a intentarlo en unos minutos.',
					},
				});
				dispatch({type: 'selectedNewsletters', value: []});
				dispatch({type: 'unselectedNewsletters', value: []});
				setTimeout(() => {
					dispatch({
						type: 'updateStatus',
						value: {status: '', message: ''},
					});
				}, 4000);
			} finally {
				dispatch({type: 'isLoading', value: false});
				setTimeout(() => {
					dispatch({type: 'selectedNewsletters', value: []});
					dispatch({type: 'unselectedNewsletters', value: []});
				}, 4000);
			}
		} else {
			selectNewsletter({id});
		}
	};

	/**
	 * Valida el email ingresado.
	 *
	 * @param {Event} e
	 */
	const validateInput = ({target: {value}}) => {
		const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
		dispatch({type: 'isValidInput', value: !!value.match(regex)});
	};

	/**
	 * Obtiene el primer elemento seleccionado
	 * @returns Primer objeto subscription seleccionado
	 */
	const getFirstSelected = () => {
		const id = state.selectedNewsletters[0];
		return (
			state.bajadas.exclusive.SPECIALS.find(s => s.id === id) ||
			state.bajadas.exclusive.ADVANCES.find(s => s.id === id) ||
			state.bajadas.newsletter.REGULAR.find(s => s.id === id) ||
			state.bajadas.newsletter.CLUB.find(s => s.id === id) ||
			state.bajadas.newsletter.MAGAZINES.find(s => s.id === id) ||
			{}
		);
	};

	const clearStateSelecions = () => {
		dispatch({
			type: 'selectedNewsletters',
			value: [],
		});
		dispatch({
			type: 'unselectedNewsletters',
			value: [],
		});
	};

	useEffect(() => {
		if (!data || data?.length === 0) return;

		const mapperSpecials = LandingMapper.parseToExclusive(
			data,
			TYPE_EXCLUSIVE.SPECIALS,
		);
		const mapperAdvanced = LandingMapper.parseToExclusive(
			data,
			TYPE_EXCLUSIVE.ADVANCES,
		);
		const mapperRegular = LandingMapper.parseToNewsletter(
			data,
			TYPE_NEWSLETTER.REGULAR,
		);
		const mapperClub = LandingMapper.parseToNewsletter(
			data,
			TYPE_NEWSLETTER.CLUB,
		);
		const mapperMagazine = LandingMapper.parseToNewsletter(
			data,
			TYPE_NEWSLETTER.MAGAZINES,
		);

		dispatch({
			type: 'bajadas',
			value: {
				...state,
				exclusive: {
					SPECIALS: [...mapperSpecials],
					ADVANCES: [...mapperAdvanced],
				},
				newsletter: {
					REGULAR: [...mapperRegular],
					CLUB: [...mapperClub],
					MAGAZINES: [...mapperMagazine],
				},
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLogged, data]);

	useEffect(() => {
		if (isLogged && state.select) {
			const updateSubscription = async () => {
				dispatch({type: 'isLoading', value: true});

				try {
					await executeFetch(urlApiSubscription, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
							'X-Token': token,
							'Authorization': `Bearer ${accessToken}`,
						},
						body: JSON.stringify({
							serviciosAgregar: state.selectedNewsletters,
							serviciosRemover: state.unselectedNewsletters,
						}),
					});
				} catch {
					dispatch({
						type: 'updateStatus',
						value: {
							status: 'error',
							message:
								'No pudimos sincronizar tus suscripciones. Volvé a intentarlo en unos minutos.',
						},
					});
				} finally {
					dispatch({type: 'isLoading', value: false});
				}
			};

			updateSubscription();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.selectedNewsletters, state.unselectedNewsletters]);

	useEffect(() => {
		dispatch({
			type: 'showToast',
			value: state.updateStatus.status === 'error' ? true : false,
		});
	}, [state.updateStatus]);

	useEffect(() => {
		dispatch({
			type: 'isLoading',
			value: loading,
		});
	}, [loading]);

	/**
	 * Una vez que se actualizan las bajadas, debemos actualizar cuáles están seleccionadas y cuáles no
	 */
	useEffect(() => {
		if (state.bajadas) syncSubscriptions();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state.bajadas]);

	return {
		...state,
		isDropdownActive,
		selectNewsletter,
		updateSubscriptions,
		validateInput,
		toggleDropdown,
		closeDropdown,
		getFirstSelected,
		clearStateSelecions,
		setShowToast,
	};
};

export default useSelections;
