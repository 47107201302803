import PropTypes from 'prop-types';

import Source from './Source';

const propTypes = {
	/**
	 * Value-key pairs.
	 *  1. Query.
	 *  2. Tamaño.
	 */
	srcSet: PropTypes.array,
	/**
	 * Función encargada de resolver
	 * la url de la imagen según
	 * un tamaño.
	 */
	imageResolver: PropTypes.func,
};

const Sources = ({ srcSet, imageResolver }) => {
	return (
		<>
			{srcSet.map(({ key, value }) => {
				return <Source media={key} srcSet={imageResolver(value)} />;
			})}
		</>
	);
};

Sources.propTypes = propTypes;

export default Sources;
