import React from 'react';


export const NewsletterContext = React.createContext({
    isLogged: false,
    isPremium: false,
    username: '',
    email: '',
    isMobile: false,
    cookieOptions: {}
});
