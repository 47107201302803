import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Logo.scss';

import { Link } from '@lana-ui/common';

const Logo = ({ name, color, href, title, target, className, size }) => {
	const classes = classNames('com-logo', className, {
		[`logo-${name}`]: name,
		'--color': color,
		[`--${size}`]: size,
	});

	return (
		<>
			{href ? (
				<Link href={href} title={title} target={target}>
					<i className={classes} />
				</Link>
			) : (
				<i className={classes} />
			)}
		</>
	);
};

Logo.propTypes = {
	name: PropTypes.string.isRequired,
	href: PropTypes.string,
	color: PropTypes.bool,
	target: PropTypes.string,
	title: PropTypes.string,
	extraClass: PropTypes.string,
	size: PropTypes.string,
};

export default Logo;
