import React from 'react';
import cookies from 'react-cookies';
import Landing from './pages/Landing';
import {LandingHead} from '@lana-ui/common';
import {NewsletterContext} from '@lib/contexts';
import {HelmetProvider} from 'react-helmet-async';
import '@styles/globals.scss';

function Newsletter() {

	// parametros por querystring
	const querystring = window.location.search
	const params = new URLSearchParams(querystring)

	const configMobile = params.get('platform') || cookies.load('platform')
	const configPremium = params.get('ProductoPremiumId') || cookies.load('ProductoPremiumId')

	const cookieOptions = {
		domain: (window.location.hostname === 'localhost') ? 'localhost' : '.lanacion.com.ar',
		path: '/'
	};

	const token = params.get('token') || cookies.load('token');
	const accessToken = params.get('access-token') || cookies.load('access-token');
	const username = cookies.load('usuario%5Fusuario');
	const email = cookies.load('usuarioemail');

	const isPremium = configPremium
		? configPremium.includes(2)
		: false;

	const isMobile = configMobile === 'mobile';

	let isTablet = false;

	const ua = navigator.userAgent;
	if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
		isTablet = true;
	}
	

	return (
		<HelmetProvider>
			<NewsletterContext.Provider
				value={{
					token,
					accessToken,
					username,
					email,
					isPremium,
					isMobile,
					isTablet,
					cookieOptions
				}}
			>
				<LandingHead />
				<Landing />
			</NewsletterContext.Provider>
		</HelmetProvider>
	);
}

export default Newsletter;
