import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Input.scss';

const propTypes = {
	/**
	 * Propiedades meta asociadas a Formik.
	 */
	meta: PropTypes.string,
	/**
	 * Icono izquierdo.
	 */
	leftIcon: PropTypes.string,
	/**
	 * Icono derecho.
	 */
	rightIcon: PropTypes.string,
	/**
	 * Atributo type asociado.
	 */
	type: PropTypes.string,
	/**
	 * Aributo name asociado.
	 */
	name: PropTypes.string,
	/**
	 * ID asociado al input.
	 */
	id: PropTypes.string,
	/**
	 * Placeholder asociado.
	 */
	placeholder: PropTypes.string,
	/**
	 * Default value.
	 */
	value: PropTypes.string,
	/**
	 * Falg, ¿está deshabilitado?
	 */
	disabled: PropTypes.bool,
	/**
	 * Descripción asociada.
	 */
	description: PropTypes.string,
	/**
	 * Etiqueta meta asociada.
	 */
	funcIconDer: PropTypes.func,
	/**
	 * Objeto de estado.
	 *  1. status (Contiene el estado)
	 *  2. msg (Contiene el mensaje)
	 */
	state: PropTypes.object,
	/**
	 * Handler para el evento change.
	 */
	onChange: PropTypes.func,
	/**
	 * Handler para el evento click.
	 */
	onClick: PropTypes.func,
	/**
	 * Handler para el evento keyDown.
	 */
	onKeyDown: PropTypes.func,
	/**
	 * Flag, ¿es de solo lectura?
	 */
	readOnly: PropTypes.bool,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
};

const defaultProps = {
	funcIconDer: () => { },
	onChange: () => { },
	onClick: () => { },
	onKeyDown: () => { },
};

const Input = props => {
	const {
		meta,
		leftIcon,
		rightIcon,
		type,
		name,
		id,
		placeholder,
		value,
		disabled,
		description,
		funcIconDer,
		state,
		onChange,
		onClick,
		onKeyDown,
		readOnly,
		className,
		...r
	} = props;
	const { status, msg } = state || {};
	const classes = classNames('com-input', className, {
		'state--error rightIcon': status === 'error',
		'state--success rightIcon': status === 'success',
		leftIcon: leftIcon,
		rightIcon: rightIcon,
	});

	return (
		<div className={classes}>
			<div className="com-input__box">
				{leftIcon && <i className={'com-input__leftIcon ' + leftIcon}></i>}

				<input
					type={type}
					name={name}
					id={id}
					value={value}
					placeholder={placeholder}
					disabled={disabled}
					readOnly={readOnly}
					{...(onKeyDown && { onKeyDown: onKeyDown })}
					{...(onChange && { onChange: onChange })}
					{...(onClick && { onClick: onClick })}
					{...meta}
					{...r}
				/>

				{rightIcon && (
					<i
						className={'com-input__rightIcon ' + rightIcon}
						{...(funcIconDer && {
							onClick: funcIconDer,
							style: { cursor: 'pointer' },
						})}></i>
				)}

				{/*meta != undefined  && meta.error &&  meta.touched*/}
				{status === 'error' && <i className="com-input__rightIcon icon-error-warning"></i>}

				{/*meta != undefined && !meta.error && meta.touched*/}
				{status === 'success' && <i className="com-input__rightIcon icon-check-mark"></i>}

				{/*meta != undefined  && meta.error &&  meta.touched*/}
				{status === 'error' ? (
					<span className="com-input__box__descrip --fivexs">{msg}</span>
				) : (
					description && (
						<span className="com-input__box__descrip --fivexs">{description}</span>
					)
				)}
			</div>
		</div>
	);
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
