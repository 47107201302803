import {useEffect, useReducer, useRef} from 'react';

const useFetch = (url, options) => {
	const cache = useRef({});
	const cancelRequest = useRef(false);

	const initialState = {
		error: undefined,
		data: undefined,
		loading: false,
	};

	const fetchReducer = (state, action) => {
		switch (action.type) {
			case 'loading':
				return {...initialState, loading: action.payload};
			case 'fetched':
				return {
					...initialState,
					data: action.payload.data,
					loading: action.payload.loading,
				};
			case 'error':
				return {
					...initialState,
					error: action.payload.error,
					loading: action.payload.loading,
				};
			default:
				return state;
		}
	};

	const [state, dispatch] = useReducer(fetchReducer, initialState);

	useEffect(() => {
		if (!url) return;

		const fetchData = async () => {
			dispatch({type: 'loading', payload: true});

			if (cache.current[url]) {
				dispatch({
					type: 'fetched',
					payload: {data: cache.current[url], loading: false},
				});
				return;
			}

			try {
				const response = await fetch(url, options);
				if (!response.ok) throw new Error(response.statusText);

				const data = await response.json();
				cache.current[url] = data;

				if (cancelRequest.current) return;

				dispatch({type: 'fetched', payload: {data, loading: false}});
			} catch (error) {
				if (cancelRequest.current) return;

				dispatch({type: 'error', payload: {error, loading: false}});
			}
		};

		fetchData();

		return () => {
			cancelRequest.current = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [url]);

	const executeFetch = async (url, options) => {
		if (!url) {
			return;
		}

		const response = await fetch(url, options);
		if (!response.ok) throw new Error(response.statusText);

		return response.ok;
	};

	return [state, executeFetch];
};

export default useFetch;
