import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {Text, Link, List, useOnClickOutside} from '@lana-ui/common';
import {desplegable} from './User.data';

import './User.scss';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Amp.
	 */
	amp: PropTypes.bool,
	/**
	 * Email del usuario.
	 */
	email: PropTypes.string,
	/**
	 * Flag, ¿posee suscripción?
	 */
	isPremium: PropTypes.bool,
	/**
	 * Logout handler.
	 */
	logout: PropTypes.func,
};

const User = ({className, email, isPremium, logout = () => {}, ...r}) => {
	const menuRef = useRef('menu');
	const [isOpen, setIsOpen] = useState(false);
	const classes = classNames('usermenu', className);
	useOnClickOutside(menuRef, () => {
		setIsOpen(false);
	});

	return (
		<div
			className={classes}
			ref={menuRef}
			onClick={() => {
				setIsOpen(!isOpen);
			}}
			tabIndex="0"
			role="button"
			id="menuUser"
			{...r}
		>
			<Link
				className="--fourxs --font-bold"
				title="Ir al menú de suscriptor digital"
			>
				{email}
			</Link>
			<Text tag="p" className="text --fivexs">
				{isPremium ? 'Suscriptor digital' : 'Sin suscripción digital'}
			</Text>
			{isOpen && (
				<List size="4xs">
					{[
						...desplegable,
						{
							onClick: logout,
							text: 'Salir',
							title: 'Desloguearte',
						},
					]}
				</List>
			)}
		</div>
	);
};

User.propTypes = propTypes;

export default User;
