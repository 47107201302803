import {useRef, cloneElement} from 'react';
import PropTypes from 'prop-types';
import useCarousel from './useCarousel';

import classNames from 'classnames';
import './_carousel.scss';

import ArrowControls from './ArrowControls';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Flag, ¿tiene las controles de
	 * anterior y siguiente?
	 */
	hasArrowControls: PropTypes.bool,
	/**
	 * Sensibilidad (en pixeles).
	 */
	threshHold: PropTypes.number,
	/**
	 * Duración de las transiciones (s).
	 */
	transition: PropTypes.number,
	/**
	 * Cantidad de cartas en desktop.
	 */
	desktopCards: PropTypes.number,
	/**
	 * Cantidad de cartas en tablet.
	 */
	tabletCards: PropTypes.number,
	/**
	 * Cantidad de cartas en mobile.
	 */
	mobileCards: PropTypes.number,
	/**
	 * Desktop custom breakpoint.
	 */
	desktopBreakpoint: PropTypes.number,
	/**
	 * Tablet custom breakpoint.
	 */
	tabletBreakpoint: PropTypes.number,
	/**
	 * Mobile custom breakpoint.
	 */
	mobileBreakpoint: PropTypes.number,
	/**
	 * Flag, ¿scroll multiple?
	 * default: individual.
	 */
	multipleSteps: PropTypes.bool,
	/**
	 * Gap entre cards.
	 */
	gap: PropTypes.number,
	/**
	 * Flag, ¿tipo de controles alternativo?
	 */
	alernativeControls: PropTypes.bool,
};

const defaultProps = {
	threshHold: 100,
	transition: 0.3,
	hasArrowControls: true,
	desktopCards: 4,
	tabletCards: 2,
	mobileCards: 1,
	desktopBreakpoint: 1340,
	tabletBreakpoint: 768,
	mobileBreakpoint: 480,
	gap: 32,
};

const Carousel = ({
	children,
	className,
	hasArrowControls,
	threshHold,
	transition,
	desktopCards,
	tabletCards,
	mobileCards,
	desktopBreakpoint,
	tabletBreakpoint,
	mobileBreakpoint,
	//multipleSteps,
	alternativeControls,
	//gap,
	...r
}) => {
	const carouselRef = useRef('carousel');
	const cardRef = useRef('card');
	const classes = classNames('carousel', className, {
		'--alternative': alternativeControls,
	});

	const {
		goBack,
		goNext,
		touchStart,
		touchMove,
		touchEnd,
		//remainingSlides,
		//screens,
		//cardsAmount,
		current,
		setCurrent,
	} = useCarousel(
		cardRef,
		carouselRef,
		children.length,
		threshHold,
		transition,
		desktopCards,
		tabletCards,
		mobileCards,
		desktopBreakpoint,
		tabletBreakpoint,
		mobileBreakpoint,
	);

	return (
		<section className={classes}>
			<div
				className="carousel__box"
				onTouchStart={touchStart}
				onMouseDown={touchStart}
				onTouchMove={touchMove}
				onMouseMove={touchMove}
				onTouchEnd={touchEnd}
				onMouseUp={touchEnd}
				onMouseLeave={touchEnd}
				onContextMenu={e => {
					e.preventDefault();
					e.stopPropagation();
				}}
				{...r}
			>
				<div className="carousel__track" ref={carouselRef}>
					{/*children.slice(-cardsAmount).map((child, i) =>
						cloneElement(child, {
							['aria-hidden']: (i + 1 !== current).toString(),
							ref: cardRef,
						}),
					)*/}
					{children.map((child, i) =>
						cloneElement(child, {
							//['aria-hidden']: [...Array(cardsAmount).keys()].includes(i).toString(),
							ref: cardRef,
						}),
					)}
					{/*children.slice(0, cardsAmount).map((child, i) =>
						cloneElement(child, {
							['aria-hidden']: (i + 1 !== current).toString(),
							ref: cardRef,
						}),
					)*/}
				</div>
			</div>
			{hasArrowControls && (
				<ArrowControls
					current={current}
					setCurrent={setCurrent}
					goNext={goNext}
					goBack={goBack}
					alternative={alternativeControls}
				/>
			)}
		</section>
	);
};

Carousel.propTypes = propTypes;
Carousel.defaultProps = defaultProps;

export default Carousel;
