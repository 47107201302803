import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { resolveCols } from './utils';

import classNames from 'classnames';
import './_item.scss';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Tipo de elemento.
	 */
	as: PropTypes.string,
	/**
	 * Flag, ¿solo reenvía la clase?
	 */
	forwards: PropTypes.bool,
	/**
	 * Columnas a ocupar.
	 */
	cols: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
};

const defaultProps = {
	as: 'div',
};

const Item = ({ children, className, as: Component, forwards, cols, ...r }) => {
	const classes = classNames('grid-item', className, resolveCols(cols));

	return !forwards ? (
		<Component className={classes} {...r}>
			{children}
		</Component>
	) : (
		<>{cloneElement(children, { className: classes, ...r })}</>
	);
};

Item.propTypes = propTypes;
Item.defaultProps = defaultProps;

export default Item;
