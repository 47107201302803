import PropTypes from 'prop-types';

import classNames from 'classnames';
import './_badge.scss';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Estado del badge.
	 */
	state: PropTypes.oneOf(['sponsored', 'multimedia']),
};

const Badge = ({ children, className, state, ...r }) => {
	const classes = classNames('badge', className, { [`--${state}`]: state });

	return (
		<span className={classes} {...r}>
			{children}
		</span>
	);
};

Badge.propTypes = propTypes;

export default Badge;
