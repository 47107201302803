import './Spinner.scss';

// TODO: unificar con el spinner.
const SimpleSpinner = () => {
	return (
		<div className="simple-spinner">
			<span className="inner" aria-busy="true" role="alert"></span>
		</div>
	);
};

export default SimpleSpinner;
