import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './_chip.scss';
import classNames from 'classnames';

import { Icon, Checkmark, SimpleSpinner } from '@lana-ui/common';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Flag, ¿se puede cerrar?
	 */
	canBeClosed: PropTypes.bool,
	/**
	 * Handler para el cierre del
	 * chip.
	 */
	handlerClose: PropTypes.func,
	/**
	 * Flag, ¿es seleccionable?
	 */
	isSelectable: PropTypes.bool,
	/**
	 * Flag, ¿está seleccionado?
	 */
	isSelected: PropTypes.bool,
	/**
	 * Código hexadecimal asociado
	 * al checkmark.
	 */
	checkmarkColor: PropTypes.string,
	/**
	 * Tipo de botón.
	 */
	type: PropTypes.oneOf(['primary', 'secondary']),
	/**
	 * Icono asociado al chip.
	 */
	icon: PropTypes.element,
	/**
	 * Flag, ¿el icono está a la
	 * izquierda?
	 */
	iconLeft: PropTypes.bool,
	/**
	 * Flag, ¿está cargando?
	 */
	isLoading: PropTypes.bool,
	/**
	 * Flag, ¿está logueado?
	 */
	isLogged: PropTypes.bool,
};

const defaultProps = {
	handlerClick: () => {},
};

const Chip = ({
	children,
	className,
	canBeClosed,
	handlerClose,
	isSelectable,
	handlerClick,
	hiddenId,
	checkmarkColor,
	type,
	icon,
	iconLeft,
	isSelected,
	pointer,
	isLoading,
	isLogged,
	...r
}) => {
	const [isActive, setIsActive] = useState(isSelected || false);
	const [loading, setLoading] = useState(isLoading || false);
	const classes = classNames('chip', className, {
		'--active': isSelectable && isActive,
		'--check': isSelectable,
		'--close': canBeClosed,
		'--icon-left': iconLeft,
		[`--${type}`]: type,
		'--pointer': pointer,
		'--loading': isLoading,
	});

	const onClick = e => {
		/*if (isLogged) {
			setLoading(true);
			// Reemplazar por llamado a callback.
			setTimeout(() => setLoading(false), 1500);
		}*/
		setIsActive(!isActive);
		handlerClick(e);
	};

	useEffect(() => {
		setIsActive(isSelected);
		setLoading(isLoading);
	}, [isSelected, isLoading]);

	return (
		<div
			className={classes}
			onClick={!isLoading ? onClick : () => {}}
			disabled={isLoading}
			aria-disabled={isLoading}
			{...r}
		>
			{iconLeft && icon}
			{!loading && isSelectable && (
				<Checkmark checkmarkColor={checkmarkColor || 'FEFEFE'} state={isActive} />
			)}
			{canBeClosed && <Icon name="close" size="s" />}
			<div className="chip__inner">{children}</div>
			{!iconLeft && icon}
			{loading && <SimpleSpinner logoSize="xs" />}
		</div>
	);
};

Chip.propTypes = propTypes;
Chip.defaultProps = defaultProps;

export default Chip;
