const Club = ({ className, ...r }) => {
	return (
		<svg
			className={className}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 25 25"
			{...r}>
			<path
				fill="#126FFF"
				d="M23.729 19.11H7.04a.95.95 0 01-.937-.938c0-.51.428-.938.937-.938h16.688a.95.95 0 01.937.938.95.95 0 01-.938.937zM13.23 14.957H6.987a.95.95 0 01-.937-.937.95.95 0 01.937-.938h6.241a.95.95 0 01.938.938.932.932 0 01-.938.938z"></path>
			<path
				fill="#126FFF"
				d="M2.541 20.93a.937.937 0 01-.857-.535c-.054-.134-.134-.268-.188-.402-.562-1.34-.83-2.785-.83-4.259a10.863 10.863 0 013.188-7.687c.99-1.018 2.17-1.795 3.455-2.357a10.962 10.962 0 014.232-.857c1.473 0 2.893.294 4.259.857a10.922 10.922 0 013.455 2.33 10.78 10.78 0 012.33 3.455c.242.536.402 1.099.563 1.661a.912.912 0 01-.696 1.125.912.912 0 01-1.125-.696 9.38 9.38 0 00-.456-1.393 9.01 9.01 0 00-4.794-4.795c-1.125-.482-2.304-.696-3.51-.696-1.205 0-2.41.24-3.508.696a9.009 9.009 0 00-4.795 4.795c-.482 1.178-.723 2.357-.723 3.562 0 1.206.241 2.411.696 3.51.054.106.108.213.134.32a.957.957 0 01-.455 1.26.615.615 0 01-.375.107z"></path>
		</svg>
	);
};

export default Club;
