import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Icon.scss';

import Link from '../link';
import {Club} from '@lana-ui/common';

const propTypes = {
	/**
	 * Nombre del icono.
	 */
	name: PropTypes.string.isRequired,
	/**
	 * Texto del icono.
	 */
	text: PropTypes.string,
	/**
	 * Atributo href del link.
	 */
	href: PropTypes.string,
	/**
	 * Atributo target del link.
	 */
	target: PropTypes.string,
	/**
	 * Atributo rel del link.
	 */
	rel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	/**
	 * Atributo title del link.
	 */
	title: PropTypes.string,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Tamaño del icono.
	 */
	size: PropTypes.oneOf(['s', 'm', 'l', 'xl']),
	/**
	 * Flag, ¿tiene wrapper?
	 */
	hasWrapper: PropTypes.bool,
	/**
	 * Aria label asociado
	 * al link.
	 */
	ariaLabel: PropTypes.string,
};

const defaultProps = {
	size: 'm',
};

const Icon = ({
	name,
	text,
	href,
	target,
	rel,
	title,
	className,
	size,
	hasWrapper,
	ariaLabel,
	...r
}) => {
	const classes = classNames('com-icon', className, `icon-${name}`, {
		[`--icon-${size}`]: size,
		'--wrapper': hasWrapper,
	});

	const icon =
		name === 'club-logo' ? (
			<div className={`${classes} special`} {...r}>
				<Club width="24" height="24" />
			</div>
		) : (
			<i className={classes} {...r} />
		);

	return (
		<>
			{href ? (
				<Link
					href={href}
					title={title}
					target={target}
					aria-label={ariaLabel}
					rel={rel}
				>
					{icon}
					{text ? <span>{text}</span> : ''}
				</Link>
			) : (
				<>{icon}</>
			)}
		</>
	);
};

Icon.propTypes = propTypes;
Icon.defaultProps = defaultProps;

export default Icon;
