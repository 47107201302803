//Contenido del footer

export const listUltimasNoticias = [
    { text: 'Tránsito y transporte', href: 'https://www.lanacion.com.ar/transito/' },
    { text: 'Clima', href: 'https://www.lanacion.com.ar/clima/' },
    { text: 'LA NACION Data', href: 'https://www.lanacion.com.ar/data/' }
];

export const listEconomia = [
    { text: 'Dólar Hoy', href: 'https://www.lanacion.com.ar/dolar-hoy/' },
    { text: 'Campo', href: 'https://www.lanacion.com.ar/economia/campo/' },
    { text: 'Propiedades', href: 'https://www.lanacion.com.ar/propiedades/' },
    { text: 'Comercio Exterior', href: 'https://www.lanacion.com.ar/economia/comercio-exterior/' },
    { text: 'Movilidad', href: 'https://www.lanacion.com.ar/autos/' },
    { text: 'Índices', href: 'https://www.lanacion.com.ar/economia/indices' },
    // { text: 'Calculadora de ganancias', href: 'https://www.lanacion.com.ar/economia/calculadora-ganancias-cuanto-se-pagara-2021-como-nid2561244/' }
];

export const listSociedad = [
    { text: 'Buenos Aires', href: 'https://www.lanacion.com.ar/buenos-aires/' },
    { text: 'Seguridad', href: 'https://www.lanacion.com.ar/seguridad/' },
    { text: 'Educación', href: 'https://www.lanacion.com.ar/educacion/' },
    { text: 'Cultura', href: 'https://www.lanacion.com.ar/cultura/' },
    { text: 'Comunidad', href: 'https://www.lanacion.com.ar/comunidad/' },
    { text: 'Bienestar', href: 'https://www.lanacion.com.ar/salud/' },
    { text: 'Ciencia', href: 'https://www.lanacion.com.ar/ciencia/' },
    { text: 'Hablemos de todo', href: 'https://www.lanacion.com.ar/comunidad/hablemos-de-todo/' }
];

export const listOpinion = [
    { text: 'Editoriales', href: 'https://www.lanacion.com.ar/editoriales/' },
    { text: 'Columnistas', href: 'https://www.lanacion.com.ar/columnistas/' },
    { text: 'Cartas de Suscriptores', href: 'https://www.lanacion.com.ar/opinion/carta-de-suscriptores/' }
];

export const listDeportes = [
    { text: 'Fútbol', href: 'https://www.lanacion.com.ar/deportes/futbol/' },
    { text: 'Rugby', href: 'https://www.lanacion.com.ar/deportes/rugby/' },
    { text: 'Tenis', href: 'https://www.lanacion.com.ar/deportes/tenis/' },
    { text: 'Canchallena', href: 'https://www.lanacion.com.ar/deportes/canchallena/' }
];

export const listLifestyle = [
    { text: 'Turismo', href: 'https://www.lanacion.com.ar/turismo/' },
    { text: 'Tecnología', href: 'https://www.lanacion.com.ar/tecnologia/' },
    { text: 'Horóscopo', href: 'https://www.lanacion.com.ar/horoscopo/' },
    { text: 'Feriados', href: 'https://www.lanacion.com.ar/feriados' },
    { text: 'Loterías y Quinielas', href: 'https://www.lanacion.com.ar/loterias/' },
    { text: 'Podcasts', href: 'https://www.lanacion.com.ar/tema/podcasts-tid64878/' },
    { text: 'Moda y Belleza', href: 'https://www.lanacion.com.ar/moda-y-belleza/' },
];

export const listEspectaculos = [
    { text: 'Cartelera de cine', href: 'https://www.lanacion.com.ar/cartelera-de-cine' },
    { text: 'Cartelera de teatro', href: 'https://www.lanacion.com.ar/cartelera-de-teatro' }
];

export const listEdicionImpresa = [
    { text: 'Acceso PDF', href: 'https://pdf.lanacion.com.ar/' },
    { text: 'Editoriales', href: 'https://www.lanacion.com.ar/editoriales/' },
    { text: 'Conversaciones de domingo', href: 'https://www.lanacion.com.ar/conversaciones-de-domingo/' },
    { text: 'Sábado', href: 'https://www.lanacion.com.ar/sabado/' },
    { text: 'Ideas', href: 'https://www.lanacion.com.ar/ideas/' },
    { text: 'Carta de lectores', href: 'https://www.lanacion.com.ar/opinion/carta-de-lectores/' },
    { text: 'Avisos fúnebres', href: 'http://servicios.lanacion.com.ar/edicion-impresa/avisos-funebres' },
    { text: 'Avisos sociales', href: 'http://servicios.lanacion.com.ar/edicion-impresa/avisos-sociales' }
];

export const listRevistas = [
    { text: 'Revista OHLALÁ!', href: 'https://www.lanacion.com.ar/revista-ohlala/' },
    { text: 'Revista ¡HOLA!', href: 'https://www.lanacion.com.ar/revista-hola/' },
    { text: 'Revista Lugares', href: 'https://www.lanacion.com.ar/revista-lugares/' },
    { text: 'Revista Living', href: 'https://www.lanacion.com.ar/revista-living/' },
    { text: 'Revista Rolling Stone', href: 'https://es.rollingstone.com/arg/' },
    { text: 'Revista Jardín', href: 'https://www.lanacion.com.ar/revista-jardin/' }
];
export const listElMundo = [
    { text: 'Estados Unidos', href: ' https://www.lanacion.com.ar/estados-unidos/' }
];



export const menuPrincipal = [
    { text: 'Últimas noticias', href: 'https://www.lanacion.com.ar/ultimas-noticias/', list: listUltimasNoticias },
    { text: 'Política', href: 'https://www.lanacion.com.ar/politica/' },
    { text: 'Economía', href: 'https://www.lanacion.com.ar/economia/', list: listEconomia },
    { text: 'El mundo', href: 'https://www.lanacion.com.ar/el-mundo/', list: listElMundo },
    { text: 'Sociedad', href: 'https://www.lanacion.com.ar/sociedad/', list: listSociedad },
    { text: 'Opinión', href: 'https://www.lanacion.com.ar/opinion/', list: listOpinion },
    { text: 'Deportes', href: 'https://www.lanacion.com.ar/deportes/', list: listDeportes },
    { text: 'Lifestyle', href: 'https://www.lanacion.com.ar/lifestyle/', list: listLifestyle },
    { text: 'Espectáculos', href: 'https://www.lanacion.com.ar/espectaculos/', list: listEspectaculos },
    { text: 'Edición impresa', href: 'https://www.lanacion.com.ar/edicion-impresa/', list: listEdicionImpresa },
    { text: 'Revistas', href: 'https://www.lanacion.com.ar/revistas/', list: listRevistas }
];

export const menuSecundario = [
    { text: 'LN Juegos', href: 'https://www.lanacion.com.ar/juegos/' },
    { text: 'Exclusivo suscriptores', href: 'https://www.lanacion.com.ar/suscriptores/' },
    { text: 'Mis notas', href: 'https://www.lanacion.com.ar/mis-notas/' },
];

export const menuTerciario = [
    { text: 'Club LA NACION', href: 'https://club.lanacion.com.ar/' , icon: 'club-ln'},
    { text: 'Canchallena', href: ' https://canchallena.lanacion.com.ar/' , icon: 'canchallena'},
    { text: 'Foodit', href: ' https://foodit.lanacion.com.ar/' , icon: 'foodit', isNew: true},
    { text: 'Bonvivir', href: 'https://bonvivir.com/' , icon: 'bonvivir'},
    { text: 'LN+', href: 'https://lnmas.lanacion.com.ar/' , icon: 'ln'},
    { text: 'Kiosco LA NACION', href: 'https://pdf.lanacion.com.ar/', icon: 'kiosco' }
]