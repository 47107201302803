import React, {useEffect, useCallback} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Toast.scss';

import Icon from '../icon';
import Title from '../title';
import Paragraph from '../paragraph';

const propTypes = {
	/**
	 * Título del toast.
	 */
	title: PropTypes.string,
	/**
	 * Estado.
	 */
	status: PropTypes.oneOf(['success', 'warning', 'info', 'alert']),
	/**
	 * Flag, ¿se cierra?
	 */
	close: PropTypes.bool,
	/**
	 * ????
	 */
	value: PropTypes.bool,
	/**
	 * Close time.
	 */
	closeAuto: PropTypes.number,
	/**
	 * Posición del flotante.
	 */
	positionFloat: PropTypes.string,
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
};

const Toast = ({
	title,
	children,
	status,
	close,
	className,
	changeValue,
	value,
	closeAuto,
	positionFloat,
	...r
}) => {
	/*
	cierra el toast agegando animacion
	value - boolean - hook state heredado de parent
	changeValue - fun - hook fun modif el value
  */
	const handleCloseToast = (value, changeValue) => {
		if (value) {
			let $toast = document.querySelector('.mod-toast');

			if ($toast === null) {
				return false;
			}

			$toast.classList.add('fadeOutUp');
			setTimeout(() => {
				changeValue(!value);
			}, 600);
		} else {
			changeValue(!value);
		}
	};

	/*
	cierra el toast con un determinado tiempo | validamos que no ejecute la fun si el user toca antes la x
	closeAuto - number - ms que queremos que tenga antes que desaparezca
  */
	const handleCloseAuto = useCallback(
		closeAuto => {
			closeAuto = typeof closeAuto === 'boolean' ? 6000 : closeAuto;

			const interval = setInterval(() => {
				if (value === true) {
					handleCloseToast(value, changeValue);
					clearInterval(interval);
				}
			}, closeAuto);
		},
		[changeValue, value],
	);

	//useEffect (didMount)
	useEffect(() => {
		if (closeAuto) {
			handleCloseAuto(closeAuto);
		}
	}, [handleCloseAuto, closeAuto]);

	const classes = classNames('mod-toast', className, {
		[`--${status}`]: status,
		[`--positionFloat --${positionFloat} fadeInDown`]: positionFloat,
	});

	return (
		<div className={classes} {...r}>
			{close && (
				<div
					onClick={() => {
						handleCloseToast(value, changeValue);
						if (typeof Function === typeof close) {
							close();
						}
					}}
				>
					<Icon name={'close'} className={'mod-toast__close'} />
				</div>
			)}

			{
				{
					success: <Icon name="check-mark" />,
					warning: <Icon name="error-warning" />,
					info: <Icon name="info" />,
					alert: <Icon name="alert" />,
				}[status]
			}

			<div className="mod-description">
				<Title tag="h3" size="--s" className="--arial --font-bold">
					{title}
				</Title>

				{children && (
					<Paragraph
						size="--twoxs"
						className="--arial --font-regular"
					>
						{children}
					</Paragraph>
				)}
			</div>
		</div>
	);
};

Toast.propTypes = propTypes;

export default Toast;
