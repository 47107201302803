const REGULAR = [
  {
    id: 208,
    title: "Actualidad Política",
    children:
      "Información confiable de los temas más relevantes de la agenda diaria, para que siempre estés informado.",
    exampleSrc: "./samples/actualidad-politica.html",
    label: "LUNES A VIERNES",
  },
  {
    id: 135,
    title: "Lo que hay que saber hoy",
    children:
      "Los temas principales de cada jornada seleccionados por los editores de LA NACION.",
    exampleSrc: "./samples/lo-que-hay-que-saber-hoy.html",
    label: "LUNES A VIERNES",
  },
  {
    id: 43,
    title: "Columnistas del día",
    children:
      "Los principales hechos y sus protagonistas según las firmas más destacadas de LA NACION.",
    exampleSrc: "./samples/columnistas.html",
    label: "TODOS LOS DÍAS",
  },
  {
    id: 118,
    title: "Lo más visto del día",
    children:
      "Conocé cuáles fueron las notas del día que más impactaron en nuestra audiencia.",
    exampleSrc: "./samples/lo-mas-visto.html",
    label: "LUNES A VIERNES",
  },
  {
    id: 241,
    title: "Hashtag",
    children:
      "Una selección de las historias más impactantes y curiosas de las celebridades, la TV y las redes sociales",
    exampleSrc: "./samples/hashtag.html",
    label: "MIÉRCOLES",
  },
  {
    id: 240,
    title: "Try Convertido",
    children:
      "Análisis, entrevistas e información sobre el rugby nacional e internacional, con la mirada de LA NACION",
    exampleSrc: "./samples/try-convertido.html",
    label: "LUNES",
  },
  {
    id: 154,
    title: "Títulos del día",
    children:
      "Los títulos principales de LA NACION para empezar el día con todo lo que tenés que saber.",
    exampleSrc: "./samples/titulares-del-dia.html",
    label: "TODOS LOS DÍAS",
  },
  {
    id: 5,
    title: "El Mundo",
    children:
      "Las notas destacadas del panorama internacional seleccionadas por los editores de LA NACION",
    exampleSrc: "./samples/el-mundo.html",
    label: "Domingos",
  },
  {
    id: 243,
    title: "Foodit",
    children:
      "Descubrí recetas fáciles y ricas, platos de autor, clases magistrales, guías, tips y ¡dale sabor a tu mesa!.",
    exampleSrc: "./samples/foodit.html",
    label: "LUNES",
  },
  {
    id: 242,
    title: "LN Juegos",
    children:
      "Accedé a anticipos exclusivos, tips e historias relacionadas con los juegos que ya forman parte de tu recreo diario.",
    exampleSrc: "./samples/ln-juegos.html",
    label: "Sábados",
  },
  {
    id: 205,
    title: "Horóscopo",
    children:
      "Las predicciones de los astros y las claves del destino en Amor, Dinero y Salud para cada día de la semana. ",
    exampleSrc: "./samples/horoscopo.html",
    label: "TODOS LOS DÍAS",
  },
  {
    id: 8,
    title: "Espectáculos",
    children:
      "Estrenos, personajes y las recomendaciones de nuestros editores para que no te quedes afuera.",
    exampleSrc: "./samples/espectaculos.html",
    label: "TODOS LOS DÍAS",
  },
  {
    id: 21,
    title: "Viajes",
    children:
      "Una guía imprescindible para conocer y planificar tu próximo viaje a los mejores destinos.",
    exampleSrc: "./samples/viajes.html",
    label: "MIÉRCOLES",
  },
  {
    id: 100,
    title: "LN Propiedades",
    children:
      "Las novedades más destacadas del mercado y las mejores oportunidades para invertir en inmuebles.",
    exampleSrc: "./samples/propiedades.html",
    label: "MARTES",
  },
  {
    id: 101,
    title: "LN Bienestar",
    children:
      "Tendencias en salud, nutrición, fitness y las claves para lograr un equilibrio entre el cuerpo, la mente y el espíritu.",
    exampleSrc: "./samples/bienestar.html",
    label: "JUEVES",
  },
  {
    id: 204,
    title: "Las historias del día",
    children:
      "No te pierdas los relatos más inspiradores de cada jornada seleccionados por nuestros editores.",
    exampleSrc: "./samples/las-historias-del-dia.html",
    label: "LUNES A VIERNES",
  },
  {
    id: 207,
    title: "Negocios",
    children:
      "Inversiones, emprendedores, actualidad económica y las claves de la semana del mundo de los negocios.",
    exampleSrc: "./samples/negocios.html",
    label: "MIÉRCOLES",
  },
  {
    id: 22,
    title: "LN Movilidad",
    children:
      "Novedades de la industria automotriz, test drives, los más vendidos, los imperdibles.",
    exampleSrc: "./samples/movilidad.html",
    label: "VIERNES",
  },
  {
    id: 23,
    title: "LN Campo",
    children:
      "Las claves de la semana del sector según los editores de LA NACION Campo.",
    exampleSrc: "./samples/campo.html",
    label: "SÁBADOS",
  },
  {
    id: 129,
    title: "Deportes",
    children:
      "La agenda diaria y los principales temas según el equipo de LA NACION Deportes.",
    exampleSrc: "./samples/deportes.html",
    label: "TODOS LOS DÍAS",
  },
  {
    id: 216,
    title: "Periodismo de Servicio",
    children:
      "Orientación y propuestas para transformar la realidad social, por el equipo de Fundación de LA NACION.",
    exampleSrc: "./samples/periodismo-de-servicio.html",
    label: "PRIMER MIÉRCOLES DE CADA MES",
  },
].map(({...d }, i) => ({
  ...d,
  isSelectable: true,
  isLogged: true,
  alt: `Newsletter de ${d.title}`,
}));

const CLUB = [
  {
    id: 209,
    icon: "restaurant",
    children: "Restos",
  },
  {
    id: 212,
    icon: "email-open",
    children: "Deco",
  },
  {
    id: 210,
    icon: "fashion",
    children: "Moda",
  },
  {
    id: 213,
    icon: "healthy",
    children: "Bienestar",
  },
  {
    id: 211,
    icon: "entertainment",
    children: "Salidas",
  },
  {
    id: 222,
    icon: "eco",
    children: "ECO Sustentable",
  },
  {
    id: 223,
    icon: "car",
    children: "Automóvil",
  },
  {
    id: 224,
    icon: "trip",
    children: "Viajes",
  },
  {
    id: 225,
    icon: "more",
    children: "Otros beneficios",
  },
].map((d, i) => ({ ...d, alt: `Newsletter de ${d.title}` }));

const MAGAZINES = [
  {
    id: 196,
    children:
      "Vidas para ser leídas, historias y entrevistas exclusivas, las mejores fotos y los eventos más espectaculares.",
    exampleSrc: "./samples/hola.html",
    label: "MIÉRCOLES",
    title: "Revista ¡HOLA!",
  },
  {
    id: 109,
    children:
      "Diseños espectaculares y las mejores ideas para tu casa de la revista líder en decoración.",
    exampleSrc: "./samples/living.html",
    title: "Revista Living",
    label: "MIÉRCOLES, CADA QUINCE DÍAS",
  },
].map(({ ...d }, i) => ({
  ...d,
  isSelectable: true,
  alt: `Newsletter de ${d.title}`,
}));

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
  REGULAR,
  CLUB,
  MAGAZINES,
};
