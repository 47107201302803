import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Button, Logo, Link, List, useOnClickOutside } from '@lana-ui/common';
import { useWindowSize } from '@lib/index';
import { menuPrincipal, menuSecundario, menuTerciario } from './Dropdown.data';

import './Dropdown.scss';
import { Icon } from '../../common';

const Menu = ({ children, className, list }) => {
	const classes = classNames('com-list', className);

	return (
		<ul className={classes}>
			{children.map((item, i) => (
				<li className="item --more" key={i}>
					<Link href={item.href} text={item.text} />
					{item.list && (
						<>
							<Button icon="arrow-down" iconSize="l" mod="" />
							<List className="--sub">{item.list}</List>
						</>
					)}
				</li>
			))}
		</ul>
	);
};

const MenuWithIcons = ({ children, className, list }) => {
	const classes = classNames('com-list', className);

	return (
		<ul className={classes}>
			{children.map((item, i) => (
				<li className="item --more" key={i}>
					<Icon className="icon-with-bg" name={item.icon} />
					<Link href={item.href} text={item.text} />
					{item.isNew && <span className='new-badge'>Nuevo</span>}
				</li>
			))}
		</ul>
	);
};

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
};

const Dropdown = ({ className, isActive, closeDropdown, ...r }) => {
	const windowSize = useWindowSize();
	const classes = classNames('wrap-dropdown', className, {
		'--active': isActive,
	});
	const dropdownRef = useRef();
	useOnClickOutside(dropdownRef, () => {
		if (windowSize > 1023) closeDropdown();
	});

	return (
		<div className={classes} role="button" tabIndex="0" {...r}>
			<div
				aria-label="overlay"
				className="overlay"
				role="button"
				tabIndex="0"
			></div>
			<div className="dropdown" ref={dropdownRef}>
				<header className="header_dropdown">
					<Logo
						name="la-nacion"
						href="https://www.lanacion.com.ar/"
						title="Ir a la página principal"
					></Logo>
					<Button
						icon="close"
						iconSize="l"
						mod=""
						onClick={closeDropdown}
					></Button>
				</header>
				<nav className="nav_dropdown --font-bold">
					<Menu className="--principal">{menuPrincipal}</Menu>
					<Menu className="--secondary">{menuSecundario}</Menu>
					<MenuWithIcons className="--terciary">{menuTerciario}</MenuWithIcons>
				</nav>
			</div>
		</div>
	);
};

Dropdown.propTypes = propTypes;

export default Dropdown;
