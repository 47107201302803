export const resolveImageUrl = slug =>
	`https://especialess3.lanacion.com.ar/LN/newsletter/landing/${slug}`;

export const reducer = (state, action) => {
	
	if (state.hasOwnProperty(action.type)) {
		return {
			...state,
			[`${action.type}`]: action.value,
		};
	}

	return state;
};

const dynamicallyLoadScript = (script, section) => {
	if (!document) return Promise.reject(new Error('No existe document'));
	if (!document.querySelector(`script[src="${script}"]`)) {
		const baseScript = document.createElement('script');
		baseScript.src = script;
		document[section].appendChild(baseScript);
		// return true;
		return new Promise((res, rej) => {
			baseScript.onload = () => {
				res();
			};
			baseScript.onerror = () => {
				rej();
			};
		});
	}
	return Promise.reject(new Error('Ya esta cargado'));
};

export const handleClickBuscar = () => {
	dynamicallyLoadScript('//www.queryly.com/js/queryly.v4.js', 'body')
		.then(() => {
			const initScript = document.createElement('script');
			initScript.innerHTML = `queryly.init('8075c0c1c4c44847', document.querySelectorAll('#root'));`;
			document.body.appendChild(initScript);
			document.getElementById('querylyButton').click();
		})
		.catch(e => {
			console.error('Script loading failed! Handle this error', e);
		});
};

/**
 * Convierte una fecha en formato ISO a dd/MM/yyyy
 * @param {String} dateISO Fecha en formato ISO
 * @returns Devuelve fecha convertida en formato dd/MM/yyyy
 */
 export const convertISODateToString = ( dateISO ) => {

    if ( !dateISO ) { return null; }

    const date = new Date(dateISO);
    return `${(date.getDate() + 1).toString().padStart(2,'0')}/${(date.getMonth()+1).toString().padStart(2,'0')}/${date.getFullYear()}`;
}
