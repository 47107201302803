//Contenido del footer

export const listFooterMenu = [
	{
		text: 'Últimas noticias',
		href: 'https://www.lanacion.com.ar/ultimas-noticias',
	},
	{text: 'Política', href: 'https://www.lanacion.com.ar/politica'},
	{text: 'Economía', href: 'https://www.lanacion.com.ar/economia'},
	{text: 'El mundo', href: 'https://www.lanacion.com.ar/el-mundo'},
	{text: 'Sociedad', href: 'https://www.lanacion.com.ar/sociedad'},
	{text: 'Opinión', href: 'https://www.lanacion.com.ar/opinion'},
	{text: 'Deportes', href: 'https://www.lanacion.com.ar/deportes'},
	{text: 'Lifestyle', href: 'https://www.lanacion.com.ar/lifestyle'},
	{text: 'Espectáculos', href: 'https://www.lanacion.com.ar/espectaculos'},
	{
		text: 'Edición impresa',
		href: 'https://www.lanacion.com.ar/edicion-impresa',
	},
	{text: 'LN+', href: 'https://lnmas.lanacion.com.ar/'},
	{text: 'Club LA NACION', href: 'https://club.lanacion.com.ar/'},
];

export const listFooterRevistas = [
	{text: 'Revistas'},
	{
		text: 'OHLALÁ!',
		href: 'https://www.lanacion.com.ar/revista-ohlala',
		title: 'Ir a Revista OHLALÁ!',
	},
	{
		text: '¡HOLA!',
		href: 'https://www.lanacion.com.ar/revista-hola',
		title: 'Ir a Revista ¡HOLA!',
	},
	{
		text: 'ROLLING STONE',
		href: 'https://www.lanacion.com.ar/revista-rolling-stone',
		title: 'Ir a Revista ROLLING STONE',
	},
	{
		text: 'LIVING',
		href: 'https://www.lanacion.com.ar/revista-living',
		title: 'Ir a Revista LIVING',
	},
	{
		text: 'BRANDO',
		href: 'https://www.lanacion.com.ar/revista-brando',
		title: 'Ir a Revista BRANDO',
	},
	{
		text: 'JARDÍN',
		href: 'https://www.lanacion.com.ar/revista-jardin',
		title: 'Ir a Revista JARDÍN',
	},
	{
		text: 'LUGARES',
		href: 'https://www.lanacion.com.ar/revista-lugares',
		title: 'Ir a Revista LUGARES',
	},
];

export const listFooterTiendas = [
	{text: 'Club del vino:'},
	{
		text: 'Bon vivir',
		href: 'http://www.bonvivir.com/',
		target: '_blank',
		title: 'Ir a tienda online de Bonvivir',
	},
];

export const listFooterServicios = [
	{text: 'Envíos:'},
	{
		text: 'HOP',
		href: 'http://www.hopenvios.com.ar/',
		target: '_blank',
		title: 'Ir a página de envíos de HOP',
	},
	{
		text: 'Colecciones',
		href: 'http://colecciones.lanacion.com.ar/',
		title: 'Ir a tienda online de Colecciones LA NACION',
	},
	{
		text: 'Máster en periodismo',
		href: 'http://www.utdt.edu/ver_contenido.php?id_contenido=1111&id_item_menu=2327',
		target: '_blank',
		title: 'Ir a la Maestría en periodismo',
	},
	{
		text: 'Fundación LA NACION',
		href: 'http://fundacionlanacion.org.ar/',
		target: '_blank',
		title: 'Ir a la Fundación LA NACION',
	},
	{
		text: 'Avisos solidarios',
		href: 'http://solidarios.lanacion.com.ar/',
		target: '_blank',
		title: 'Ir a los clasificados solidarios',
	},
];

export const listFooterLinks = [
	{
		text: 'Mapa del sitio',
		href: 'http://www.lanacion.com.ar/mapa-del-sitio',
		title: 'Ir al mapa del sitio de LA NACION',
	},
	{
		text: 'Ayuda',
		href: 'https://micuenta.lanacion.com.ar/ayuda',
		title: 'Ir a las preguntas frecuentes',
	},
	{
		text: 'Términos y condiciones',
		href: 'https://micuenta.lanacion.com.ar/tyc',
		title: 'Ir a los términos y condiciones',
	},
	{
		text: '¿Cómo anunciar?',
		href: 'https://www.lanacion.in/',
		title: 'Cómo anunciar en el sitio de LA NACION',
		target: '_blank',
		rel: true,
	},
	{
		text: 'Suscribirse al diario impreso',
		href: 'https://suscripciones.lanacion.com.ar/suscribirme/',
		title: 'Ir a las suscripciones al diario',
	},
];

export const listFooterLegales = [
	{text: 'Protegido por reCAPTCHA:'},
	{
		text: 'Condiciones',
		href: 'https://policies.google.com/terms?hl=es-419',
		target: '_blank',
		title: 'Ir a las condiciones de Google',
		rel: true,
	},
	{
		text: 'Privacidad',
		href: 'https://policies.google.com/privacy?hl=es-419',
		target: '_blank',
		title: 'Ir a la privacidad de Google',
		rel: true,
	},
];
