import { useEffect, useRef, useState } from 'react';
import { useElementDimensions, useWindowSize } from '../helpers';

const useCarousel = (
	cardRef,
	carouselRef,
	childrenLength,
	threshHold,
	transition,
	desktopCards,
	tabletCards,
	mobileCards,
	desktopBreakpoint,
	tabletBreakpoint,
) => {
	const SECOND = 1000;
	const dragging = useRef(false);
	const startPos = useRef(0);
	const currentTranslate = useRef(0);
	const prevTranslate = useRef(0);
	const windowSize = useWindowSize();
	const [current, setCurrent] = useState(0);
	const isPaused = useRef(false);
	let cardDimensions = useElementDimensions(cardRef, windowSize);
	let cardWidth = cardDimensions?.width;
	let displacement =
		windowSize >= desktopBreakpoint ? 40 : windowSize >= tabletBreakpoint ? 16 : 0;
	let cardTotalWidth = cardWidth + displacement;

	/**
	 * En base al viewport, devuelve la cantidad
	 * de cards que se determinaron para el mismo.
	 *
	 * @returns {Number} - Número de cards por viewport
	 */
	const cardsAmount = (() => {
		if (windowSize >= desktopBreakpoint) return desktopCards;
		else if (windowSize >= tabletBreakpoint) return tabletCards;
		else return mobileCards;
	})();

	const screens = Math.ceil(childrenLength / cardsAmount);

	/**
	 * Resuelve si faltan slides para
	 * completar la última pantalla.
	 *
	 * @returns {Number} - Cantidad de cards faltantes.
	 */
	const remainingSlides = () => {
		return cardsAmount - (childrenLength % cardsAmount);
	};

	/**
	 * Obtener la posición del mouse.
	 *
	 * @param {EventSource} e
	 */
	const getPositionX = e => {
		return e.type.includes('mouse') ? e.pageX : e.touches[0].clientX;
	};

	const touchStart = e => {
		startPos.current = getPositionX(e);
		dragging.current = true;
		carouselRef.current.style.cursor = 'grabbing';
	};

	const touchMove = e => {
		if (dragging.current) {
			const currentPos = getPositionX(e);
			currentTranslate.current = prevTranslate.current + currentPos - startPos.current;
		}
	};

	const touchEnd = () => {
		if (dragging.current) {
			const movedBy = currentTranslate.current - prevTranslate.current;
			dragging.current = false;

			if (movedBy < -threshHold) goNext(current, setCurrent, childrenLength);

			if (movedBy > threshHold) goBack(current, setCurrent, childrenLength);

			currentTranslate.current = 0;
			carouselRef.current.style.cursor = 'grab';
		}
	};

	/**
	 * Apagar las transiciones de un elemento.
	 *
	 * @param {ReactRef} ref
	 */
	const transitionOff = ref => (ref.current.style.transition = 'none');

	/**
	 * Encender TODAS[!] las transiciones de un elemento.
	 *
	 * @param {} ref
	 */
	const transitionOn = ref => (ref.current.style.transition = `${transition}s ease-in-out`);

	/**
	 * Ir una slide hacia atrás.
	 *
	 * @param {number} current - Slide actual.
	 * @param {Function} setCurrent - Setter del estado.
	 */
	const goBack = (current, setCurrent) => {
		if (!isPaused.current) {
			isPaused.current = true;
			transitionOff(carouselRef);
			carouselRef.current.prepend(carouselRef.current.lastElementChild);
			carouselRef.current.style.transform = `translate3d(${-cardTotalWidth}px, 0, 0)`;

			setTimeout(() => {
				transitionOn(carouselRef);
				carouselRef.current.style.transform = `translate3d(0, 0, 0)`;
			}, 0.01 * SECOND);

			setTimeout(() => (isPaused.current = false), (transition + 0.3) * SECOND);
		}
	};

	/**
	 * Ir una slide hacia adelante.
	 *
	 * @param {number} current - Slide actual.
	 * @param {Function} setCurrent - Setter del estado.
	 */
	const goNext = (current, setCurrent) => {
		if (!isPaused.current) {
			isPaused.current = true;
			transitionOn(carouselRef);
			carouselRef.current.style.transform = `translate3d(-${cardTotalWidth}px, 0, 0)`;

			function listener() {
				handlerTransitionend();
			}

			const handlerTransitionend = () => {
				carouselRef.current.appendChild(carouselRef.current.firstElementChild);
				transitionOff(carouselRef);
				carouselRef.current.style.transform = `translate3d(0, 0, 0)`;

				carouselRef.current.removeEventListener('transitionend', listener);
			};

			carouselRef.current.addEventListener('transitionend', listener);

			setTimeout(() => (isPaused.current = false), (transition + 0.2) * SECOND);
		}
	};

	/**
	 * Handler para manejar las acciones
	 * de las teclas de dirección.
	 *
	 * @param {EventSource} - event
	 */
	const handleKeyDown = ({ key }) => {
		const nextKeys = ['ArrowRight', 'l'].includes(key);
		const backKeys = ['ArrowLeft', 'h'].includes(key);
		if (backKeys) goBack(current, setCurrent, childrenLength);
		if (nextKeys) goNext(current, setCurrent, childrenLength);
	};

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		};
	});

	return {
		goNext,
		goBack,
		touchStart,
		touchMove,
		touchEnd,
		remainingSlides,
		screens,
		cardsAmount,
		current,
		setCurrent,
	};
};

export default useCarousel;
