import PropTypes from 'prop-types';

import classNames from 'classnames';
import './_picture.scss';

import { defaultMedia } from './config.js';
import Sources from './Sources';
import Badge from '../badge';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * URL de la imagen.
	 */
	src: PropTypes.string,
	/**
	 * Atributo alt asociado.
	 */
	alt: PropTypes.string,
	/**
	 * ¿Carga perezosa?
	 */
	lazy: PropTypes.bool,
	/**
	 * Value-key pairs.
	 *  1. Query.
	 *  2. Tamaño.
	 */
	srcSet: PropTypes.array,
	/**
	 * Breakpoints a matchear.
	 */
	media: PropTypes.array,
	/**
	 * Ancho default.
	 */
	width: PropTypes.number,
	/**
	 * Alto default.
	 */
	height: PropTypes.number,
	/**
	 * Función encargada de resolver
	 * la url de la imagen según
	 * un tamaño.
	 */
	imageResolver: PropTypes.func,
	/**
	 * Label asociado.
	 */
	label: PropTypes.string,
};

const defaultProps = {
	lazy: true,
	media: defaultMedia,
};

const Picture = ({
	className,
	srcSet,
	media,
	alt,
	lazy,
	src,
	width,
	height,
	imageResolver,
	aspectRatio,
	title,
	longDesc,
	label,
	...r
}) => {
	const classes = classNames('picture', className, { [`--${aspectRatio}`]: aspectRatio });

	return (
		<>
			{src && (
				<picture className={classes} {...r}>
					{srcSet && <Sources srcSet={srcSet} imageResolver={imageResolver} />}
					<img
						className="image"
						src={src}
						alt={alt}
						width={width}
						height={height}
						title={title}
						longdesc={longDesc}
						{...(lazy && { loading: 'lazy' })}
					/>
					<Badge state="sponsored">{label}</Badge>
				</picture>
			)}
		</>
	);
};

Picture.propTypes = propTypes;
Picture.defaultProps = defaultProps;

export default Picture;
