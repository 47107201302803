/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
	production: process.env.REACT_APP_PRODUCTION === 'true',
	newsletterApi: process.env.REACT_APP_ENDPOINT_NEWSLETTER,
	loginUrl: process.env.REACT_APP_URL_LOGIN,
	paywallUrl: process.env.REACT_APP_URL_PAYWALL,
	gaId: process.env.REACT_APP_GA_ID,
	hjId: process.env.REACT_APP_HJ_ID,
	hjSv: process.env.REACT_APP_HJ_SV,
};
