import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import './Footer.scss';

import {List, Logo, Icon, Text, Image, Layout} from '@lana-ui/common';
import {
	listFooterMenu,
	listFooterRevistas,
	listFooterTiendas,
	listFooterServicios,
	listFooterLinks,
	listFooterLegales,
} from './Footer.data';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Footer completo.
	 */
	isFull: PropTypes.bool,
};

const Footer = ({className, isFull}) => {
	const classes = classNames('footer-site --threexs', className);

	return (
		<Layout as="footer" state="wrapper" className={classes}>
			<div className="lay">
				<section className="top">
					<div className="row">
						<div className="col-desksm-4 --left">
							<Icon
								name="facebook-filled"
								title="Seguirnos en Facebook"
								href="https://www.facebook.com/lanacion"
								target="_blank"
								rel
							/>
							<Icon
								name="twitter-filled"
								title="Seguirnos en Twitter"
								href="https://twitter.com/LANACION"
								target="_blank"
								rel
							/>
							<Icon
								name="instagram"
								title="Seguirnos en Instagram"
								href="https://www.instagram.com/lanacioncom/"
								target="_blank"
								rel
							/>
							<Icon
								name="rss"
								title="Ir a RSS"
								href="https://www.lanacion.com.ar/arc/outboundfeeds/rss/?outputType=xml"
								target="_blank"
								rel
							/>
						</div>
						<div className="col-desksm-4 --center">
							<Logo
								name="la-nacion"
								size="--sm"
								href="https://www.lanacion.com.ar/"
								title="Ir a la página principal"
							></Logo>
						</div>
						<div className="col-desksm-4 --right">
							<Image
								src="https://arc-static.glanacion.com/pf/resources/images/google-play.svg?d=522"
								alt="Descargar nuestra app en Google Play"
								width="120"
								height="35"
								href="https://play.google.com/store/apps/details?id=app.lanacion.activity&hl=es_419"
								target="_blank"
								rel
							/>
							<Image
								src="https://arc-static.glanacion.com/pf/resources/images/app-store.svg?d=522"
								alt="Descargar nuestra app en el App Store"
								width="120"
								height="35"
								href="https://apps.apple.com/ar/app/la-nacion/id410689702"
								target="_blank"
								rel
							/>
						</div>
					</div>
				</section>
				{isFull && (
					<section className="middle">
						<div className="row">
							<List inline className="--font-bold">
								{listFooterMenu}
							</List>
							<List
								inline
								size="--fourxs"
								className="--magazine --font-bold"
							>
								{listFooterRevistas}
							</List>
							<List
								inline
								size="--fourxs"
								className="--club --font-bold"
							>
								{listFooterTiendas}
							</List>
							<List
								inline
								size="--fourxs"
								className="--delivery --font-bold"
							>
								{listFooterServicios}
							</List>
						</div>
					</section>
				)}
				<section className="bottom">
					<div className="row">
						<div className="col-desksm-9 col-deskxl-8 --left">
							<List className="--bullet-xs" inline>
								{listFooterLinks}
							</List>
						</div>
						<div className="col-desksm-3 col-deskxl-4 --right">
							<List inline>{listFooterLegales}</List>
						</div>
					</div>

					<div className="row">
						<div className="col-desksm-6 --right">
							<Image
								href="http://gda.com/"
								target="_blank"
								src="https://arc-static.glanacion.com/pf/resources/images/gda.svg?d=522"
								width="36"
								height="20"
								alt="GDA"
								className="img_gda"
								rel
							/>
							<Text>
								Miembro de GDA. Grupo de Diarios América
							</Text>
							<Image
								href="https://serviciosweb.afip.gob.ar/clavefiscal/qr/publicInfoD.aspx"
								target="_blank"
								src="https://arc-static.glanacion.com/pf/resources/images/data-fiscal.svg?d=522"
								alt="Data fiscal"
								width="28"
								height="38"
								rel
							/>
						</div>
						<div className="col-desksm-6 --left">
							<Text tag="p">
								Copyright 2021 SA LA NACION | Todos los derechos
								reservados
							</Text>
						</div>
					</div>
				</section>
			</div>
		</Layout>
	);
};

Footer.propTypes = propTypes;

export default Footer;
