import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Image.scss';

import Link from '../link';

const propTypes = {
	src: PropTypes.string.isRequired,
	amp: PropTypes.bool,
	alt: PropTypes.string,
	className: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
	withLazy: PropTypes.bool,
	href: PropTypes.string,
	target: PropTypes.string,
};

const Image = ({
	amp,
	src,
	alt,
	className,
	width,
	height,
	withLazy,
	href,
	target,
	rel,
	...r
}) => {
	const CustomTag = amp ? 'amp-img' : 'img';
	const loading = withLazy ? 'lazy' : undefined;
	const classes = classNames('com-image', className);

	const image = (
		<CustomTag
			src={src}
			width={width}
			height={height}
			loading={loading}
			className={classes}
			alt={alt || ''}
			{...r}
		/>
	);

	return (
		<>
			{href ? (
				<Link href={href} title={alt} target={target} rel={rel}>
					{image}
				</Link>
			) : (
				image
			)}
		</>
	);
};

Image.propTypes = propTypes;

export default Image;
