import PropTypes from 'prop-types';
import { resolveSpacings, resolveCols } from './utils';

import classNames from 'classnames';
import './_grid.scss';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Tipo de elemento.
	 */
	as: PropTypes.string,
	/**
	 * Espaciado en la resolución
	 * xs: > 320px
	 * Si se indica un solo valor,
	 * el espaciado es general,
	 * Sino de puede utilizar un objeto
	 * que determine el espaciado
	 * horizontal: x y vertical: y.
	 * {x, y} = {'xl', 'm'}
	 */
	spacingXs: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	/**
	 * Columnas.
	 * Se puede determinar un valor
	 * numérico para todas las resoluciones,
	 * o un objeto con las distintas resoluciones.
	 * {xs: 1, m: 2, xl: 4}
	 */
	cols: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
};

const defaultProps = {
	as: 'div',
};

const Grid = ({
	className,
	children,
	as: Component,
	spacing,
	spacingXs,
	spacingS,
	spacingM,
	spacingL,
	spacingLm,
	spacingLg,
	spacingXl,
	cols,
	...r
}) => {
	const spacings = { spacing, spacingXs, spacingM, spacingL, spacingLm, spacingLg, spacingXl };
	const classes = classNames('grid', className, resolveSpacings(spacings), resolveCols(cols));

	return (
		<Component className={classes} {...r}>
			{children}
		</Component>
	);
};

Grid.propTypes = propTypes;
Grid.defaultProps = defaultProps;

export default Grid;
