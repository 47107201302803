import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { getFontFamily, getFontSize, getFontWeight } from '../helpers/getFontData';

const propTypes = {
	tag: PropTypes.string,
	className: PropTypes.string,
	font: PropTypes.string,
	size: PropTypes.string,
	weight: PropTypes.string,
	color: PropTypes.string,
	bold: PropTypes.bool,
	highlight: PropTypes.bool,
	capital: PropTypes.bool,
};

const Text = ({
	tag,
	className,
	font,
	size,
	weight,
	bold,
	highlight,
	capital,
	children,
	text,
	color,
}) => {
	const CustomTag = tag || 'span';
	const _content = children || text;

	const _font = getFontFamily(font);
	const _size = getFontSize(size);
	const _weight = getFontWeight(weight);

	const classes = classNames('text', className, {
		[_font]: font,
		[_weight]: weight,
		[_size]: size,
		'--font-bold': bold,
		'--font-highlight': highlight,
		'--capital': capital,
	});
	const _color = { color: `${color}` };

	if (!_content) return null;

	return (
		<CustomTag className={classes} style={_color}>
			{_content}
		</CustomTag>
	);
};

Text.propTypes = propTypes;

export default Text;
