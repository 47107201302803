import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Spinner.scss';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Color del logo y del círculo.
	 */
	color: PropTypes.string,
	/**
	 * Nombre del logo a utilizarse.
	 */
	logoName: PropTypes.string,
	/**
	 * Tamaño del logo.
	 */
	logoSize: PropTypes.string,
};

const defaultProps = {
	logoName: 'ln',
	logoSize: 'xxl',
};

const Spinner = ({className, color, logoName, logoSize, ...r}) => {
	const classes = classNames('com-spinner', className);

	return (
		<div className={classes} {...r}>
			<svg height="90" width="90">
				<circle
					cx="45"
					cy="45"
					r="37"
					strokeWidth="4"
					stroke={`#${color}` || '#006998'}
				/>
			</svg>
			<svg
				className="ln"
				width="44.7"
				height="21"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M0 19.557V21h18.446v-8.36h-1.523v.904c0 4.774-3.228 6.013-7.274 6.013h-.725V1.442h3.393V0H0v1.442h3.08v18.115H0zm20.873 0V21h7.62v-1.443h-.598c-1.933 0-2.702-.289-2.702-1.988V4.09h.07L38.643 21h2.55V3.045c0-.749.65-1.603 2.23-1.603h.75V0h-7.636v1.442h.922c1.54 0 2.377.551 2.377 1.695v9.27h-.071L29.915 0H20.98v1.442h.527c1.161 0 2.347.924 2.347 2.348v13.623c0 1.817-1.01 2.144-2.085 2.144h-.896z"
					fill={`#${color}` || '#006998'}
				/>
			</svg>
		</div>
	);
};

Spinner.propTypes = propTypes;
Spinner.defaultProps = defaultProps;

export default Spinner;
