import {COMMON_PROPS} from './data';
import {useSelections, useUser} from '@lib/index';

import './_landing.scss';
import classNames from 'classnames';

import {Footer, Header, Dropdown, Navbar} from '@lana-ui/arc';
import {NewsletterArticle, NewsletterArticleClub} from '@lana-ui/club';
import {
	Layout,
	Breadcrumb,
	Title,
	Link,
	Grid,
	GridItem,
	Paragraph,
	Button,
	Badge,
	Drawer,
	Input,
	Checkmark,
	Toast,
	LNExclusive
} from '../../components/common';
import {NewsletterContext} from '../../lib/contexts';
import {useContext, useEffect, useState} from 'react';

const NewsletterLanding = () => {
	const {isLogged, isPremium, email, logout, login, suscribe} = useUser();

	const {isMobile} = useContext(NewsletterContext);

	const {
		selectedNewsletters,
		selectNewsletter,
		updateSubscriptions,
		validateInput,
		getFirstSelected,
		clearStateSelecions,
		isValidInput,
		isLoading,
		updateStatus,
		isDropdownActive,
		toggleDropdown,
		closeDropdown,
		bajadas: {exclusive: EXCLUSIVE, newsletter: NEWSLETTER},
		showToast,
		setShowToast,
	} = useSelections(isLogged);

	const drawerClasses = classNames(updateStatus.status, {
		'--mobile': isMobile,
	});

	const generateId = word => word?.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/[^a-zA-Z0-9 ]/g, "").replaceAll(" ", "-").toLowerCase();

	const [sectionMounted, setSectionMounted] = useState(false)

	const scrollToComponent = (component) => component.scrollIntoView({block: "center", behavior: "smooth"})

	useEffect(() => {
	  if (sectionMounted) {
		  setTimeout(() => {
			  const section = document.querySelector(window.location.hash)
			  scrollToComponent(section)
		  }, 500);
	  }
	}, [sectionMounted])

	return (
		<>
			{!isMobile && (
				<>
					<Header
						toggleDropdown={toggleDropdown}
						isLogged={isLogged}
						logout={() => logout(clearStateSelecions)}
						login={login}
						isPremium={isPremium}
						suscribe={suscribe}
						email={email}
					/>
					<Dropdown
						isActive={isDropdownActive}
						closeDropdown={closeDropdown}
					/>
					<Navbar toggleDropdown={toggleDropdown} />
				</>
			)}
			<Layout className="newsletters">
				{!isLogged && (
					<Drawer
						className={drawerClasses}
						isOpen={!!selectedNewsletters.length}
						isWrapped
						isLoading={isLoading}
						isMobile={isMobile}
					>
						{!updateStatus.status ? (
							<>
								<Title
									as="h3"
									size="m"
									className="--font-regular --sueca"
								>
									{selectedNewsletters.length > 1 ? (
										<>
											Seleccionaste
											<strong>{` ${selectedNewsletters.length} `}</strong>
											newsletters
										</>
									) : selectedNewsletters.length ? (
										<>
											Seleccionaste el newsletter de
											<strong>{` ${
												getFirstSelected()?.title
											}`}</strong>
										</>
									) : (
										'Ningún newsletter seleccionado'
									)}
								</Title>
								<form
									onSubmit={e => {
										updateSubscriptions(e);
									}}
								>
									<Input
										name="email"
										placeholder="mail@lanacion.com.ar"
										onChange={validateInput}
									/>
									<Button
										label={`RECIBIR NEWSLETTER${
											selectedNewsletters.length > 1
												? 'S'
												: ''
										}`}
										mod="secondary"
										type="submit"
										disabled={!isValidInput}
									/>
								</form>
							</>
						) : (
							updateStatus.status === 'success' && (
								<div className="box">
									<Checkmark
										isWrapped
										checkmarkColor="22B120"
									/>
									<Paragraph
										size="m"
										className="--font-bold --sueca"
									>
										¡Listo!{' '}
										<span className="--font-medium">
											Pronto recibirás
											{` ${
												selectedNewsletters.length > 1
													? 'nuestros newsletters.'
													: 'nuestro newsletter.'
											}`}
										</span>
									</Paragraph>
								</div>
							)
						)}
					</Drawer>
				)}
				{!isMobile && (
					<Layout as="section" className="heading" state="wrapper">
						<Breadcrumb>
							<Link href="https://lanacion.com.ar">LA NACION</Link>
							<span>Newsletters</span>
						</Breadcrumb>
						<Title
							as="h1"
							size="xl"
							className={COMMON_PROPS.TITLE.className}
						>
							Newsletters
						</Title>
					</Layout>
				)}
				<Layout
					as="section"
					className="exclusive"
					style={isMobile === true ? {marginTop: 'unset'} : {}}
					darkMode
				>
					<Layout as="div" state="wrapper">
						<Grid
							spacing={{x: 'lg'}}
							spacingM={{y: 'sm'}}
							spacingXl={{y: 'xl'}}
							cols={{l: 1, m: 3, xs: 1}}
							className="break-grid"
						>
							<GridItem>
								<LNExclusive className='exclusive'/>
								<Paragraph size="twoxs">
									{!isLogged ? (
										<>
											Necesitás
											{!isMobile ? (
												<Link onClick={login}>
													{' '}
													Iniciar sesión{' '}
												</Link>
											) : (
												' Iniciar sesión '
											)}
											para disfrutar de los newsletters
											exclusivos para suscriptores.
										</>
									) : isPremium ? (
										<>
											Disfrutá de nuestros newsletters
											exclusivos para suscriptores de LA
											NACION.
										</>
									) : (
										<>
											Necesitás tener suscripción digital
											para disfrutar de los newsletters
											exclusivos para suscriptores.
											{!isMobile ? (
												<Link onClick={suscribe}>
													{' '}
													Suscribite hoy
												</Link>
											) : (
												' Suscribite hoy '
											)}
										</>
									)}
								</Paragraph>
							</GridItem>
							<GridItem></GridItem>
						</Grid>
						<Grid
							spacing={{x: 'lg'}}
							spacingM={{y: 'sm'}}
							spacingXl={{y: 'xl'}}
							cols={{l: 4, m: 3, xs: 1}}
						>
							{EXCLUSIVE.SPECIALS.map(
								({children, id, ...articleProps}, i) => (
									<GridItem forwards key={i}>
										<NewsletterArticle
											{...articleProps}
											handlerClick={e => {
												!isLogged
													? selectNewsletter({
															id,
															title: articleProps.title,
													  })
													: updateSubscriptions(e, {
															id,
													  });
											}}
											isSelected={selectedNewsletters.some(
												n => n === id,
											)}
											isLoading={isLoading}
											isLogged={isLogged}
											isPremium={isPremium}
											id={generateId(articleProps.title)}
											onMounted={setSectionMounted}
										>
											{children}
										</NewsletterArticle>
									</GridItem>
								),
							)}
						</Grid>
					</Layout>
				</Layout>
				<Layout as="section" className="regular" state="wrapper">
					<Title {...COMMON_PROPS.TITLE}>Más newsletters</Title>
					<Grid
						spacing={{x: 'lg'}}
						spacingM={{y: 'sm'}}
						spacingXl={{y: 'xl'}}
						cols={{l: 4, m: 3, xs: 1}}
					>
						{NEWSLETTER.REGULAR.map(
							({children, id, ...articleProps}, i) => (
								<GridItem forwards key={`regular-${i}`}>
									<NewsletterArticle
										{...articleProps}
										handlerClick={e => {
											!isLogged
												? selectNewsletter({
														id,
														title: articleProps.title,
												  })
												: updateSubscriptions(e, {id});
										}}
										isSelected={selectedNewsletters.some(
											n => n === id,
										)}
										isLogged={isLogged}
										isLoading={isLoading}
										id={generateId(articleProps.title)}
										onMounted={setSectionMounted}
									>
										{children}
									</NewsletterArticle>
								</GridItem>
							),
						)}
						{NEWSLETTER.MAGAZINES.map(
							({children, id, ...articleProps}, i) => (
								<GridItem forwards key={`magazine-${i}`}>
									<NewsletterArticle
										{...articleProps}
										handlerClick={e => {
											!isLogged
												? selectNewsletter({
														id,
														title: articleProps.title,
												  })
												: updateSubscriptions(e, {id});
										}}
										isSelected={selectedNewsletters.some(
											n => n === id,
										)}
										isLogged={isLogged}
										isLoading={isLoading}
										id={generateId(articleProps.title)}
										onMounted={setSectionMounted}
									>
										{children}
									</NewsletterArticle>
								</GridItem>
							),
						)}
					</Grid>
				</Layout>
				<Layout as="section" className="club" state="wrapper" id="club">
					<Title {...COMMON_PROPS.TITLE}>Club LA NACION</Title>
					<Grid
						className="break-grid vertical full-max-width"
						cols={{xs: 1, m: 3, l: 4}}
						spacingXl={{y: 'xl'}}
						spacingXs={'sm'}
					>
						<GridItem className="box">
							<Paragraph size="twoxs">
								Descubrí los mejores beneficios que te ofrece
								Club LA NACION en gastronomía, moda,
								espectáculos y mucho más!
							</Paragraph>
							<Badge state="sponsored">UNA VEZ POR SEMANA</Badge>
							<Button
								tag="a"
								mod="tertiary"
								target="_blank"
								href="./samples/club.html"
								label="EJEMPLO"
							/>
						</GridItem>
						{NEWSLETTER.CLUB.map(
							({title, id, ...articleProps}, i) => (
								<GridItem forwards key={i}>
									<NewsletterArticleClub
										{...articleProps}
										handlerClick={e => {
											!isLogged
												? selectNewsletter({
														id,
														title: title,
												  })
												: updateSubscriptions(e, {id});
										}}
										isSelected={selectedNewsletters.some(
											n => n === id,
										)}
										isLogged={isLogged}
										isLoading={isLoading}
										id={generateId(articleProps?.title)}
									>
										{title}
									</NewsletterArticleClub>
								</GridItem>
							),
						)}
					</Grid>
				</Layout>
			</Layout>
			{!isMobile ? <Footer /> : ''}
			{showToast && (
				<Toast
					status="warning"
					title="Ha ocurrido un error"
					closeAuto={3000}
					positionFloat="right"
					value={showToast}
					changeValue={setShowToast}
				>
					{updateStatus.message}
				</Toast>
			)}
		</>
	);
};

export default NewsletterLanding;
