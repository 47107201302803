import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './Breadcrumb.scss';
import '@lana-ui/common/list/List.scss';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
};

const Breadcrumb = ({ className, children, ...r }) => {
	const classes = classNames('com-breadcrumb --twoxs', className);

	return (
		<div className={classes} {...r}>
			{children}
		</div>
	);
};

Breadcrumb.propTypes = propTypes;

export default Breadcrumb;
