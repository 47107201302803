//Contenido del menú de usuario

export const desplegable = [
	{text: 'Mi cuenta', href: 'https://myaccount.lanacion.com.ar/mi-usuario'},
	{
		text: 'Mis datos',
		href: 'https://myaccount.lanacion.com.ar/datos-personales',
	},
	{
		text: 'Mis suscripciones',
		href: 'https://micuenta.lanacion.com.ar/mis-suscripciones',
	},
];
