import PropTypes from 'prop-types';

import classNames from 'classnames';
import './_newsletter-article-club.scss';

import {Icon, Title, Chip} from '@lana-ui/common';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Nombre del icono.
	 */
	icon: PropTypes.string,
	/**
	 * Flag, ¿está destacada?
	 */
	isHighlighted: PropTypes.bool,
	/**
	 * Flag, ¿se encuentra logeado
	 * el usuario?
	 */
	isLogged: PropTypes.bool,
	/**
	 * Flag, ¿se está procesando
	 * información?
	 */
	isLoading: PropTypes.bool,
	/**
	 * Handler del botón
	 * para el evento click.
	 */
	handlerClick: PropTypes.func,
	/**
	 * Flag, ¿está seleccionado?
	 */
	isSelected: PropTypes.bool,
};

const NewsletterArticleClub = ({
	children,
	className,
	icon,
	isHighlighted,
	isLogged,
	handlerClick,
	isSelected,
	isLoading,
	fechaAlta,
	suscripto,
	...r
}) => {
	const classes = classNames('newsletter-article-club', className, {
		'--highlighted': isHighlighted,
		'--logged': isLogged,
	});

	return (
		<article className={classes} {...r}>
			<Icon hasWrapper name={icon} />
			<Title size="twoxs">{children}</Title>
			<Chip
				{...(!isLogged && {
					icon: <Icon name="plus" size="s" />,
					isSelectable: true,
				})}
				{...(isLogged && {type: 'secondary'})}
				{...(isHighlighted && {className: '--negative'})}
				type={isSelected ? 'primary' : 'secondary'}
				{...!isSelected && ({icon: <Icon name="plus" size="s" />})}
				pointer
				iconLeft
				isSelectable
				handlerClick={handlerClick}
				isSelected={isSelected}
				isLoading={isLoading}
			>
				{isLogged && isSelected
					? 'RECIBIENDO'
					: isLogged && !isSelected
					? 'RECIBIR NEWSLETTER'
					: isSelected
					? 'SELECCIONADO'
					: 'SELECCIONAR'}
			</Chip>
		</article>
	);
};

NewsletterArticleClub.propTypes = propTypes;

export default NewsletterArticleClub;
