import {useRef} from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import './_drawer.scss';

import {Spinner} from '@lana-ui/common';
import {useElementDimensions, useWindowSize} from '@lana-ui/common';

const propTypes = {
	/**
	 * Clases adicionales.
	 */
	className: PropTypes.string,
	/**
	 * Flag, ¿está abierto?
	 */
	isOpen: PropTypes.bool,
	/**
	 * Flag, ¿está contenido?
	 */
	isWrapped: PropTypes.bool,
	/**
	 * Flag, ¿hay contenido cargando?
	 * Esperas asíncronas.
	 */
	isLoading: PropTypes.bool,
};

const Drawer = ({
	children,
	className,
	isOpen,
	isWrapped,
	isLoading,
	isMobile,
	loadingColor,
	...r
}) => {
	const drawerRef = useRef();
	const dimensions = useElementDimensions(drawerRef);
	const classes = classNames('drawer', className, {'--open': isOpen});
	const content = isLoading ? (
		<Spinner color={loadingColor || 'FFF'} />
	) : (
		children
	);
	const windowSize = useWindowSize();
	const props = {
		className: classes,
		style: {
			bottom: isOpen
				? windowSize >= 1024 || isMobile
					? '0px'
					: '56px'
				: `-${dimensions?.height}px`,
		},
		ref: drawerRef,
		...r,
	};

	return isWrapped ? (
		<article {...props}>
			<div className="w">{content}</div>
		</article>
	) : (
		<article {...props}>{content}</article>
	);
};

Drawer.propTypes = propTypes;

export default Drawer;
